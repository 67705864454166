import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TextField, Snackbar, Paper } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import { connect } from 'react-redux';
import { feathersAuthentication } from '../redux/FeathersRedux';
import LoadingButton from '../components/LoadingButton';
import MySnackbarContent from '../components/MySnackbar';

const styles = theme => ({
	main: {
		backgroundColor: '#212121',
		width: '100%',
		minHeight: '100vh',
		display: 'flex',
		alignItems: 'center'
	},
	container: {
		width: 'auto',
		display: 'block', // Fix IE 11 issue.
		marginLeft: theme.spacing.unit * 3,
		marginRight: theme.spacing.unit * 3,
		[theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
			width: 400,
			marginLeft: 'auto',
			marginRight: 'auto'
		}
	},
	paper: {
		// marginTop: theme.spacing.unit * 8,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
			.spacing.unit * 3}px`
	},
	avatar: {
		margin: theme.spacing.unit,
		maxHeight: 150,
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing.unit
	},
	submit: {
		marginTop: theme.spacing.unit * 3
	}
});

class LoginContainer extends Component {
	state = {
		email: '',
		password: '',
		snackbar: {
			open: false,
			message: '',
		}
	};

	logIn = event => {
		event.preventDefault();

		const { email, password } = this.state

		this.props.authenticate(email, password)
			.catch(() => {
				if (this.props.auth.isError.message) {
					if (this.props.auth.isError.message === 'Missing credentials' || this.props.auth.isError.message === 'Invalid login') {
						this.openSnackbar('Credenciales inválidas')
					}
					else if (this.props.auth.isError.message === 'Socket connection timed out') {
						this.openSnackbar('No se pudo conectar al servidor')
					}
					else {
						this.openSnackbar(this.props.auth.isError.message)
					}
				}
			})
		
	};

	openSnackbar = (message) => {
		this.setState({
			snackbar: {
				open: true,
				message,
			}
		})
	}

	/**
	 * Closes Snackbar component
	 */
	closeSnackbar = () => {
		this.setState({
			snackbar: {
				...this.state.snackbar,
				open: false
			}
		})
	}

	render() {
		const { classes } = this.props;

		return (
			<main className={classes.main}>
				<div className={classes.container}>
					<Paper className={classes.paper}>
						<img
							alt="logo"
							className={classes.avatar}
							src={require('../assets/images/logo.png')}
						/>
						<form className={classes.form} onSubmit={this.logIn}>
							<TextField
								required
								fullWidth
								label="Email"
								autoFocus
								autoComplete="email"
								margin="normal"
								variant="outlined"
								value={this.state.email}
								onChange={event => {
									this.setState({ email: event.target.value });
								}}
							/>

							<TextField
								required
								fullWidth
								label="Contraseña"
								autoComplete="current-password"
								margin="normal"
								variant="outlined"
								type="password"
								value={this.state.password}
								onChange={event => {
									this.setState({ password: event.target.value });
								}}
							/>

							<LoadingButton
								type="submit"
								fullWidth
								variant="contained"
								color="primary"
								className={classes.submit}
								loading={this.props.auth.isLoading}
								loadingProps={{
									style: { color: 'white' },
									size: 25
								}}
							>
								Iniciar sesión
							</LoadingButton>
						</form>
					</Paper>
				</div>

				<Snackbar
					anchorOrigin={{
						vertical: 'top',
						horizontal: 'right'
					}}
					open={this.state.snackbar.open}
					autoHideDuration={5000}
					onClose={this.closeSnackbar}
				>
					<MySnackbarContent
						message={this.state.snackbar.message}
						variant="error"
						onClose={this.closeSnackbar}
					/>
				</Snackbar>
			</main>
		);
	}
}

LoginContainer.propTypes = {
	classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
	auth: state.auth
});

const mapStateToDispatch = dispatch => ({
	authenticate: (email, password) =>
		dispatch(
			feathersAuthentication.authenticate({
				strategy: 'local',
				email,
				password
			})
		)
});

export default withStyles(styles)(
	connect(
		mapStateToProps,
		mapStateToDispatch
	)(LoginContainer)
);
