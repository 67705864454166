import { createStore, applyMiddleware, compose } from 'redux';
import reduxThunk from 'redux-thunk';
import reduxPromiseMiddleware from 'redux-promise-middleware';
import reduxMulti from 'redux-multi';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// creates the store
export default rootReducer => {
	/* ------------- Redux Configuration ------------- */

	const middleware = [];

	/* ------------- Redux Thunk --------------------- */
	middleware.push(reduxThunk);
	middleware.push(reduxMulti);
	middleware.push(reduxPromiseMiddleware);

	/* ------------- Redux Middlware ?? ----------- */

	// const reduxMiddleware = createReactNavigationReduxMiddleware('root', state => state.nav);
	// middleware.push(reduxMiddleware)

	/* ------------- Assemble Middleware ------------- */

	const store = createStore(
		rootReducer,
		composeEnhancers(applyMiddleware(...middleware))
	);

	return store;
};
