import React, { Component } from 'react'
import {
	Toolbar,
	Typography,
	IconButton,
	Popover,
	TextField,
	MenuItem,
	Button,
	Grid,
	InputAdornment,
	Chip
} from '@material-ui/core'
import {
	// Clear,
	// ArrowDropUp,
	// ArrowDropDown,
	AddCircle as AddCircleIcon,
	Refresh as RefreshIcon,
	FilterList as FilterIcon,
	Info as InfoIcon
} from '@material-ui/icons'
// import { DateRangePicker } from 'react-dates'
import { Link } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
// import feathersClient from '../../redux/FeathersRedux'
// import MuiDownshift from 'mui-downshift'
import moment from 'moment'
import 'moment/locale/es'

moment.locale('es')

const ROLES = [
	{
		label: 'Admin',
		value: 'ADMIN'
	},
	{
		label: 'Operador',
		value: 'OPERATOR'
	},
	{
		label: 'Supervisor',
		value: 'SUPERVISOR'
	}
]

const ACTIVE_STATUS = [
	{
		label: 'Activo',
		value: 'true'
	},
	{
		label: 'Inactivo',
		value: 'false'
	}
]

const styles = theme => ({
	iconSmall: {
		fontSize: 20
	},
	actionButton: {
		padding: theme.spacing.unit / 2
	},
	chip: {
		marginRight: 8
	}
})

const INITIAL_FILTERS = {
	role: {
		label: '',
		value: ''
	},
	name: {
		label: '',
		value: '',
		type: 'search'
	},
	lastName: {
		label: '',
		value: '',
		type: 'search',
	},
	active: {
		label: '',
		value: ''
	},
	createdAt: null
}

class UsersListToolbar extends Component {
	state = {
		filters: INITIAL_FILTERS,
		appliedFilters: {},
		// clients: [],
		focusedInput: null,
		dates: {
			startDate: null,
			endDate: null
		}
	}

	updateFilters = (name, value, doFilter = false) => {
		let newValue = value

		if (typeof value === 'string') {
			newValue = {
				label: value,
				value
			}
		}

		this.setState(
			{
				filters: {
					...this.state.filters,
					[name]: {
						...this.state.filters[name],
						...newValue
					}
				}
			},
			() => {
				if (doFilter) {
					this.doFilter()
				}
			}
		)
	}

	clearFilters = () => {
		this.setState(
			{
				filters: INITIAL_FILTERS
			},
			this.doFilter
		)
	}

	// getClients = async query => {
	// 	try {
	// 		const clients = await feathersClient.service('clients').find({
	// 			query: {
	// 				$or: [
	// 					{ name: { $search: query } },
	// 					{ lastName: { $search: query } },
	// 					{ clientNumber: { $search: query } }
	// 				]
	// 			}
	// 		})

	// 		this.setState({
	// 			clients: clients.data.map(c => {
	// 				return {
	// 					label: `${c.name} ${c.lastName}`,
	// 					value: c._id
	// 				}
	// 			})
	// 		})
	// 	} catch (e) {}
	// }

	// onClientChange = client => {
	// 	this.updateFilters('client', client)
	// }

	doFilter = () => {
		this.setState(
			{
				appliedFilters: {
					...this.state.filters,
					createdAt: {
						...this.state.dates
					}
				}
			},
			() => {
				this.props.onFilter && this.props.onFilter(this.getQueryForFilters())
			}
		)
	}

	getQueryForFilters = () => {
		let query = {}
		const { appliedFilters } = this.state

		Object.keys(appliedFilters).forEach(f => {
			if (
				f === 'createdAt' &&
				appliedFilters.createdAt.startDate &&
				appliedFilters.createdAt.endDate
			) {
				query['createdAt'] = {
					$gte: appliedFilters.createdAt.startDate.toDate(),
					$lte: appliedFilters.createdAt.endDate.toDate()
				}
			}

			if (f && appliedFilters[f].value) {
				if (appliedFilters[f].type && appliedFilters[f].type === 'search') {
					query[f] = {
						$search: appliedFilters[f].value
					}
				} else {
					query[f] = appliedFilters[f].value
				}
			}
		})

		return query;
	}

	refreshData = () => {
		this.props.refreshData && this.props.refreshData()
	}

	render() {
		const { classes } = this.props
		const { anchorEl } = this.state
		const open = Boolean(anchorEl)

		return (
			<React.Fragment>
				<Toolbar>
					<Typography variant="h6" style={{ flex: 1 }}>
						{'Lista de usuarios'}
					</Typography>
					<IconButton
						color="primary"
						onClick={event => {
							this.setState({
								anchorEl: event.currentTarget
							})
						}}
					>
						<FilterIcon />
					</IconButton>
					<Popover
						id="simple-popper"
						PaperProps={{
							style: {
								overflow: 'visible'
							}
						}}
						open={open}
						anchorEl={anchorEl}
						onClose={() => {
							this.setState({
								anchorEl: null
							})
						}}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'center'
						}}
						transformOrigin={{
							vertical: 'top',
							horizontal: 'center'
						}}
					>
						<div style={{ padding: '0 16px 16px', minWidth: 500 }}>
							<form onSubmit={(e) => {
								e.preventDefault()

								this.doFilter()
							}}>
								<div style={{ textAlign: 'center', margin: '16px 0' }}>
									<Button
										type="submit"
										variant="text"
										style={{ marginRight: 5 }}
										onClick={this.doFilter}
									>
										Filtrar
									</Button>
									<Button
										variant="text"
										style={{ fontSize: 12 }}
										onClick={this.clearFilters}
									>
										Reiniciar
									</Button>
								</div>

								<Grid container spacing={16}>
									<Grid item lg={6}>
										<TextField
											label="Nombre"
											value={this.state.filters.name.value}
											onChange={e => {
												this.updateFilters('name', e.target.value)
											}}
											margin="none"
											variant="outlined"
											fullWidth
										/>
									</Grid>
									<Grid item lg={6}>
										<TextField
											label="Apellido"
											value={this.state.filters.lastName.value}
											onChange={e => {
												this.updateFilters('lastName', e.target.value)
											}}
											margin="none"
											variant="outlined"
											fullWidth
										/>
									</Grid>
									<Grid item lg={6}>
										<TextField
											select
											label="Rol"
											className={classes.textField}
											fullWidth
											value={this.state.filters.role.value}
											onChange={e => {
												const role = ROLES.find(f => f.value === e.target.value)

												this.updateFilters('role', {
													...role
												})
											}}
											SelectProps={{
												MenuProps: {
													className: classes.menu
												}
											}}
											margin="none"
											variant="outlined"
										>
											{ROLES.map(option => (
												<MenuItem key={option.value} value={option.value}>
													{option.label}
												</MenuItem>
											))}
										</TextField>
									</Grid>
									<Grid item lg={6}>
										<TextField
											select
											label="Estatus"
											className={classes.textField}
											fullWidth
											value={this.state.filters.active.value}
											onChange={e => {
												const active = ACTIVE_STATUS.find(
													f => f.value === e.target.value
												)

												this.updateFilters('active', {
													...active
												})
											}}
											SelectProps={{
												MenuProps: {
													className: classes.menu
												}
											}}
											margin="none"
											variant="outlined"
										>
											{ACTIVE_STATUS.map(option => (
												<MenuItem key={option.value} value={option.value}>
													{option.label}
												</MenuItem>
											))}
										</TextField>
									</Grid>
								</Grid>
							</form>
						</div>
					</Popover>
					<IconButton color="primary" onClick={this.refreshData}>
						<RefreshIcon />
					</IconButton>

					<IconButton component={Link} to="/users/create" color="secondary">
						<AddCircleIcon />
					</IconButton>
				</Toolbar>
				<div style={{ padding: '0 16px' }}>
					{Object.keys(this.state.appliedFilters).map(f => {
						if (
							f === 'createdAt' &&
							this.state.appliedFilters.createdAt.startDate &&
							this.state.appliedFilters.createdAt.endDate
						) {
							return (
								<Chip
									label={`${this.state.appliedFilters.createdAt.startDate.format(
										'DD/MM/YYYY'
									)} -> ${this.state.appliedFilters.createdAt.endDate.format(
										'DD/MM/YYYY'
									)}`}
									className={classes.chip}
									onDelete={() => {
										this.setState(
											{
												dates: {
													startDate: null,
													endDate: null
												},
												filters: {
													...this.state.filters,
													createdAt: null
												}
											},
											this.doFilter
										)
									}}
								/>
							)
						}

						if (this.state.appliedFilters[f].value) {
							return (
								<Chip
									label={this.state.appliedFilters[f].label}
									className={classes.chip}
									onDelete={() => {
										this.updateFilters(f, { label: '', value: '' }, true)
									}}
								/>
							)
						}

						return null
					})}
				</div>
			</React.Fragment>
		)
	}
}

export default withStyles(styles)(UsersListToolbar)
