import React, { Component } from 'react'
import { CircularProgress } from '@material-ui/core'

class Segment extends Component {
	render() {
		return (
			<div style={{ position: 'relative' }}>
				{this.props.loading && (
					<div
						style={{
							position: 'absolute',
							backgroundColor: 'rgba(255,255,255,0.7)',
							width: '100%',
							height: '100%',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							zIndex: 1
						}}
					>
						<CircularProgress />
					</div>
				)}
				{this.props.children}
			</div>
		)
	}
}

export default Segment
