import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
    Paper,
    Toolbar,
    Typography,
    Link,
    Button,
    Grid,
    TextField,
    Snackbar,
    MenuItem,
    Divider,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions
} from '@material-ui/core'
import Segment from '../../components/Segment'
import { Link as RouterLink } from 'react-router-dom'
import SaveIcon from '@material-ui/icons/Save'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import MySnackbarContent from '../../components/MySnackbar'
import { Delete as DeleteIcon, AddBox } from '@material-ui/icons'
import { services } from '../../redux/FeathersRedux'

const styles = theme => ({
    button: {
        margin: theme.spacing.unit,
    },
    leftIcon: {
        marginRight: theme.spacing.unit,
    },
    rightIcon: {
        marginLeft: theme.spacing.unit,
    },
    iconSmall: {
        fontSize: 20,
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
})

const INITIAL_DATA = {
    clientNumber: '',
    name: '',
    lastName: '',
    address: '',
    city: '',
    suburb: '',
    zipcode: '',
    residenceType: '',
    keyword: '',
    stUser: '',
    stPassword: '',
    stToken: '',
    contacts: [],
}

const CONTACTS_DATA = {
        name: '',
        email: '',
        phone: '',
        description: '',
        callPriority: 0,
}

class ClientsCreate extends Component {
    state = {
        loading: false,
        data: INITIAL_DATA,
        snackbar: {
            open: false,
            message: '',
            variant: 'success',
        },
        contacts: CONTACTS_DATA,
        dialogOpen: false,
    }

    /**
     * Set state for the current data
     */
    handleDataChange = name => (event) => {
        const { value } = event.target

        let newValue = value

        if (name === 'area_radio' && value) {
            newValue = +value
        }

        this.setState({
            data: {
                ...this.state.data,
                [name]: newValue,
            },
        })
    }

        /**
     * Set state for the current data for contacts
     */
    handleContactsDataChange = name => (event) => {
        const { value } = event.target

        let newValue = value

        if (name === 'area_radio' && value) {
            newValue = +value
        }

        this.setState({
            contacts: {
                ...this.state.contacts,
                [name]: newValue,
            },
        })
    }

    checkDisabledContacts = () => {
        const { name, email, phone, description, callPriority} = this.state.contacts

        return name === '' || email === '' || phone === '' || description === '' || callPriority === 0
    }

    checkDisabled = () => {
        const { clientNumber, name, lastName, address, city, suburb, zipcode, residenceType, keyword, stUser, stPassword, stToken, contacts } = this.state.data

        return clientNumber === '' || name === '' || lastName === '' || address === '' || city === '' || suburb === '' || zipcode === '' || residenceType === '' || keyword === '' || stUser === '' || stPassword === '' || stToken === '' || contacts.length === 0
    }

    onDialogClose = () => {
		this.setState({
			dialogOpen: false,
		})
    }

    openDialogdAddContact = () => {
        this.setState({
			dialogOpen: true,
		})
    }

    /**
     * Enables Snackbar component which displays API response
     */
    openSnackbar = (message, variant) => {
        this.setState({
            snackbar: {
                open: true,
                message,
                variant,
            },
        })
    }

    /**
     * Closes Snackbar component
     */
    closeSnackbar = () => {
        this.setState({
            snackbar: {
                ...this.state.snackbar,
                open: false,
            },
        })
    }

    save = async () => {
        this.setState({ loading: true })
        this.props.clientsCreate(this.state.data)
        .then(res => {
            console.log(res);
            this.openSnackbar('Guardo correctamente', 'success')
            this.setState({ loading: false, data: INITIAL_DATA })
        })
        .catch(err => {
            console.log(err);
            this.openSnackbar('Hubo un error al guardar', 'error')
            this.setState({ loading: false })
        })
    }

    addContact = () => {
        const { contacts } = this.state.data
        contacts.push(this.state.contacts)
        this.setState({
            data: {
                ...this.state.data,
                contacts
            },
            contacts: CONTACTS_DATA,
            dialogOpen: false,
        })
    }

    removeItem = ( index ) => {
        const { contacts } = this.state.data

        contacts.splice(index, 1)
        this.setState({
            data: {
                ...this.state.data,
                contacts
            },
        })
    }

    renderToolbar = () => {
        const { classes } = this.props

        return (
            <Toolbar>
                <Typography variant="h6" style={{ flex: 1 }}>
                    <Link component={RouterLink} to="/clients">
                        Clientes
                    </Link>
                        {' > Crear'}
                </Typography>

                <Button
                    onClick={this.save}
                    size="small"
                    color="primary"
                    variant="contained"
                    disabled={this.checkDisabled()}
                >
                    <SaveIcon className={classNames(classes.leftIcon, classes.iconSmall)} />
                    Guardar
                </Button>
            </Toolbar>
        )
    }

    renderDialogAddContact = () => {

        return (
            <Dialog
                open={this.state.dialogOpen}
                onClose={this.onDialogClose}
                fullWidth
                maxWidth="md"
            >
                <DialogTitle>Agregar contacto</DialogTitle>
                <DialogContent>
                    <Grid container spacing={16} style={{ padding: '16px 0'}}>
                        <Grid item xs={12} lg={6} md={6}>
                            <TextField
                                id="name"
                                label="Nombre"
                                value={this.state.contacts.name}
                                onChange={this.handleContactsDataChange('name')}
                                margin="none"
                                variant="outlined"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} lg={6} md={6}>
                            <TextField
                                id="email"
                                label="Correo Electrónico"
                                value={this.state.contacts.email}
                                onChange={this.handleContactsDataChange('email')}
                                margin="none"
                                variant="outlined"
                                fullWidth
                                type="email"
                            />
                        </Grid>
                        <Grid item xs={12} lg={6} md={6}>
                            <TextField
                                id="phone"
                                label="Teléfono"
                                value={this.state.contacts.phone}
                                onChange={this.handleContactsDataChange('phone')}
                                margin="none"
                                variant="outlined"
                                fullWidth
                                type="number"
                            />
                        </Grid>
                        <Grid item xs={12} lg={6} md={6}>
                            <TextField
                                id="callPriority"
                                label="Prioridad"
                                value={this.state.contacts.callPriority}
                                onChange={this.handleContactsDataChange('callPriority')}
                                margin="none"
                                variant="outlined"
                                fullWidth
                                type="number"
                            />
                        </Grid>
                        <Grid item xs={12} lg={12} md={12}>
                            <TextField
                                id="description"
                                label="Descripción"
                                value={this.state.contacts.description}
                                onChange={this.handleContactsDataChange('description')}
                                margin="none"
                                variant="outlined"
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.onDialogClose}>Cancelar</Button>
                    <Button
                        variant="contained"
                        onClick={this.addContact}
                        color="primary"
                        autoFocus
                        disabled={this.checkDisabledContacts()}
                    >
                        {'Agregar'}
                    </Button>
                </DialogActions>

            </Dialog>
        )
    }

    renderNoContacts = () => {
        if(this.state.data.contacts.length === 0) {
            return (
            <TableRow>
                <TableCell colSpan={6} align="center">
                    <Typography variant="subtitle1">
                        Aún no hay contactos agregados
                    </Typography>
                </TableCell>
            </TableRow>
            )
        } else return null
    }

    renderForm = () => {
        const { classes } = this.props
        return (
            <form>
                <Grid container spacing={16} style={{ paddingLeft: 16, paddingRight: 16, paddingBottom: 16 }}>

                    <Grid item xs={12} lg={12} md={12}>
                        <Typography variant="subtitle1" style={{ flex: 1 }}>
                            Datos personales
                        </Typography>
                    </Grid>
                    <Grid item xs={12} lg={4} md={4}>
                        <TextField
                            id="clientNumber"
                            label="Número de Cliente"
                            value={this.state.data.clientNumber}
                            onChange={this.handleDataChange('clientNumber')}
                            margin="none"
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} lg={4} md={4}>
                        <TextField
                            id="name"
                            label="Nombre"
                            value={this.state.data.name}
                            onChange={this.handleDataChange('name')}
                            margin="none"
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} lg={4} md={4}>
                        <TextField
                            id="lastName"
                            label="Apellido"
                            value={this.state.data.lastName}
                            onChange={this.handleDataChange('lastName')}
                            margin="none"
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>

                    <Grid item xs={12} lg={12} md={12}>
                        <Typography variant="subtitle1" style={{ flex: 1 }}>
                            Datos de ubicación
                        </Typography>
                    </Grid>
                    <Grid item xs={12} lg={4} md={4}>
                        <TextField
                            id="address"
                            label="Dirección"
                            value={this.state.data.address}
                            onChange={this.handleDataChange('address')}
                            margin="none"
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} lg={4} md={4}>
                        <TextField
                            id="city"
                            label="Ciudad"
                            value={this.state.data.city}
                            onChange={this.handleDataChange('city')}
                            margin="none"
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} lg={4} md={4}>
                        <TextField
                            id="suburb"
                            label="Colonia"
                            value={this.state.data.suburb}
                            onChange={this.handleDataChange('suburb')}
                            margin="none"
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>

                    <Grid item xs={12} lg={4} md={4}>
                        <TextField
                            id="zipcode"
                            label="Código Postal"
                            value={this.state.data.zipcode}
                            onChange={this.handleDataChange('zipcode')}
                            margin="none"
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} lg={4} md={4}>
                        <TextField
                            id="betweenStreets"
                            label="Entre calles"
                            value={this.state.data.betweenStreets}
                            onChange={this.handleDataChange('betweenStreets')}
                            margin="none"
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} lg={4} md={4}>
                        <TextField
                            select
                            id="residenceType"
                            label="Tipo de vivienda"
                            value={this.state.data.residenceType}
                            onChange={this.handleDataChange('residenceType')}
                            margin="none"
                            variant="outlined"
                            fullWidth
                        >
                            <MenuItem
                                key="residential"
                                value="RESIDENTIAL"
                            >
                                Residencial
                            </MenuItem>
                            <MenuItem
                                key="comercial"
                                value="COMMERCIAL"
                            >
                                Comercial
                            </MenuItem>
                        </TextField>
                    </Grid>

                    <Grid item xs={12} lg={12} md={12}>
                        <Typography variant="subtitle1" style={{ flex: 1 }}>
                            Datos de usuario
                        </Typography>
                    </Grid>
                    <Grid item xs={12} lg={3} md={3}>
                        <TextField
                            id="keyword"
                            label="Palabra clave"
                            value={this.state.data.keyword}
                            onChange={this.handleDataChange('keyword')}
                            margin="none"
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} lg={3} md={3}>
                        <TextField
                            id="stUser"
                            label="Usuario"
                            value={this.state.data.stUser}
                            onChange={this.handleDataChange('stUser')}
                            margin="none"
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} lg={3} md={3}>
                        <TextField
                            id="stPassword"
                            label="Contraseña ST"
                            value={this.state.data.stPassword}
                            onChange={this.handleDataChange('stPassword')}
                            margin="none"
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} lg={3} md={3}>
                        <TextField
                            id="stToken"
                            label="Token"
                            value={this.state.data.stToken}
                            onChange={this.handleDataChange('stToken')}
                            margin="none"
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>

                </Grid>

                <Divider />

                <Grid container spacing={16} style={{ paddingLeft: 16, paddingRight: 16, paddingBottom: 16 }}>
                    <Grid item xs={12} lg={12} md={12}>
                        <Toolbar disableGutters>
                            <Typography variant="subtitle1" style={{ flex: 1}}>
                                {'Contactos'}
                            </Typography>

                            <Button
                                onClick={this.openDialogdAddContact}
                                size="small"
                                color="primary"
                                variant="contained"
                            >
                                <AddBox className={classNames(classes.leftIcon, classes.iconSmall)} />
                                Añadir Contacto
                            </Button>
                        </Toolbar>
                    </Grid>

                </Grid>

                <this.renderDialogAddContact />

                <Grid container spacing={16} style={{ paddingLeft: 16, paddingRight: 16, paddingBottom: 16 }}>
                    <Table>

                        <TableHead>
                            <TableRow>
                                <TableCell>Nombre</TableCell>
                                <TableCell>Correo</TableCell>
                                <TableCell>Teléfono</TableCell>
                                <TableCell>Descripción</TableCell>
                                <TableCell>Prioridad</TableCell>
                                <TableCell>Eliminar</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.data.contacts.map((row, index) => (
                                <TableRow key={row.index}>
                                    <TableCell>{row.name}</TableCell>
                                    <TableCell>{row.email}</TableCell>
                                    <TableCell>{row.phone}</TableCell>
                                    <TableCell>{row.description}</TableCell>
                                    <TableCell>{row.callPriority}</TableCell>
                                    <TableCell>
                                        <IconButton
                                            color="default"
                                            title="Delete"
                                            onClick={() => {
                                                this.removeItem(index)
                                            }}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                </TableCell>
                                </TableRow>
                            ))}
                            <this.renderNoContacts />
                        </TableBody>
                    </Table>
                </Grid>
            </form>
        )
    }

    render() {
        return (
            <Paper>
                <this.renderToolbar />

                <Segment loading={this.state.loading}>
                    <this.renderForm />
                </Segment>

                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    open={this.state.snackbar.open}
                    autoHideDuration={10000}
                    onClose={this.closeSnackbar}
                >
                    <MySnackbarContent
                        message={this.state.snackbar.message}
                        variant={this.state.snackbar.variant}
                        onClose={this.closeSnackbar}
                    />
                </Snackbar>
            </Paper>
        )
    }
}

const mapStateToProps = state => ({
})

const mapDispatchToProps = (dispatch) => ({
    clientsCreate: (client) => dispatch(services.clients.create(client)),
})

export default withStyles(styles, {withTheme: true})(connect(
    mapStateToProps,
    mapDispatchToProps,
    )(ClientsCreate))