import React, { Component, Fragment } from 'react'
import {
	AppBar,
	Toolbar,
	IconButton,
	Typography,
	Grid,
	DialogContent,
	Divider,
	ExpansionPanel,
	ExpansionPanelSummary,
	ExpansionPanelDetails,
	Table,
	TableHead,
	TableRow,
	TableBody,
	TableCell,
	Chip
} from '@material-ui/core'
import {
	// Refresh as RefreshIcon,
	Add as AddIcon,
	Close as CloseIcon,
	Info as InfoIcon
} from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'
import Segment from '../../components/Segment'
import moment from 'moment'

const styles = {
	chipIntrusion: {
		backgroundColor: '#e53935',
		height: 28,
		fontSize: 12,
		color: 'white'
	},
	chip: {
		backgroundColor: '#1565c0',
		height: 28,
		fontSize: 12
	}
}

class ClientsDetailDialog extends Component {
	onClose = () => {
		this.props.onClose && this.props.onClose()
	}

	render() {
		const { client, classes } = this.props

		return (
			<Fragment>
				<AppBar style={{ position: 'relative' }}>
					<Toolbar>
						<IconButton
							color="inherit"
							aria-label="Close"
							onClick={this.onClose}
						>
							<CloseIcon />
						</IconButton>

						<Typography variant="h6" color="inherit">
							{`Detalles de ${client.name}`}
						</Typography>
					</Toolbar>
				</AppBar>
				<DialogContent>
					<Grid container spacing={16} style={{ padding: '16px 0' }}>
						<Grid item xs={12} lg={6} md={6}>
							<Typography variant="subtitle1">Información General</Typography>
							<Divider />

							<Grid container spacing={16} style={{ paddingTop: 10 }}>
								<Grid item xs={12} lg={4} md={4}>
									<Typography variant="subtitle2">
										{'Número de cliente: '}
									</Typography>
								</Grid>
								<Grid item xs={12} lg={8} md={8}>
									<Typography
										variant="body2"
										style={{ display: 'inline-block' }}
									>
										{client.clientNumber}
									</Typography>
								</Grid>

								<Grid item xs={12} lg={4} md={4}>
									<Typography variant="subtitle2">{'Nombre: '}</Typography>
								</Grid>
								<Grid item xs={12} lg={8} md={8}>
									<Typography
										variant="body2"
										style={{ display: 'inline-block' }}
									>{`${client.name} ${client.lastName}`}</Typography>
								</Grid>

								<Grid item xs={12} lg={4} md={4}>
									<Typography variant="subtitle2">{'Dirección: '}</Typography>
								</Grid>
								<Grid item xs={12} lg={8} md={8}>
									<Typography
										variant="body2"
										style={{ display: 'inline-block' }}
									>{`${client.address}, entre ${client.betweenStreets}, ${
										client.suburb
									}, ${client.city}, ${client.zipcode}.`}</Typography>
								</Grid>

								<Grid item xs={12} lg={4} md={4}>
									<Typography variant="subtitle2">
										{'Tipo de residencia: '}
									</Typography>
								</Grid>
								<Grid item xs={12} lg={8} md={8}>
									<Typography
										variant="body2"
										style={{ display: 'inline-block' }}
									>{`${client.residenceType}`}</Typography>
								</Grid>

								<Grid item xs={12} lg={4} md={4}>
									<Typography variant="subtitle2">
										{'Palabra secreta: '}
									</Typography>
								</Grid>
								<Grid item xs={12} lg={8} md={8}>
									<Typography
										variant="body2"
										style={{ display: 'inline-block' }}
									>{`${client.keyword}`}</Typography>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={12} lg={6} md={6}>
							<Typography variant="subtitle1">Contactos del cliente</Typography>
							<Divider />
							{client.contacts.length === 0 && (
								<Typography
									variant="h5"
									style={{ margin: '16px 0', color: 'grey' }}
								>
									{`${client.name} todavía no tiene contactos asignados`}
								</Typography>
							)}
							{client.contacts.map(c => (
								<div>
									<ExpansionPanel>
										<ExpansionPanelSummary>
											<Typography
												variant="body2"
												style={{ display: 'inline-block' }}
											>{`${c.callPriority} - ${c.name}`}</Typography>
										</ExpansionPanelSummary>
										<ExpansionPanelDetails>
											<Grid container spacing={8} style={{ paddingTop: 10 }}>
												<Grid item xs={12} lg={4} md={4}>
													<Typography variant="subtitle2">
														{'Nombre: '}
													</Typography>
												</Grid>
												<Grid item xs={12} lg={8} md={8}>
													<Typography
														variant="body2"
														style={{ display: 'inline-block' }}
													>
														{c.name}
													</Typography>
												</Grid>

												<Grid item xs={12} lg={4} md={4}>
													<Typography variant="subtitle2">
														{'Correo: '}
													</Typography>
												</Grid>
												<Grid item xs={12} lg={8} md={8}>
													<Typography
														variant="body2"
														style={{ display: 'inline-block' }}
													>
														{c.email}
													</Typography>
												</Grid>

												<Grid item xs={12} lg={4} md={4}>
													<Typography variant="subtitle2">
														{'Teléfono: '}
													</Typography>
												</Grid>
												<Grid item xs={12} lg={8} md={8}>
													<Typography
														variant="body2"
														style={{ display: 'inline-block' }}
													>
														{c.phone}
													</Typography>
												</Grid>

												<Grid item xs={12} lg={4} md={4}>
													<Typography variant="subtitle2">
														{'Prioridad: '}
													</Typography>
												</Grid>
												<Grid item xs={12} lg={8} md={8}>
													<Typography
														variant="body2"
														style={{ display: 'inline-block' }}
													>
														{c.callPriority}
													</Typography>
												</Grid>
											</Grid>
										</ExpansionPanelDetails>
									</ExpansionPanel>
									<Divider />
								</div>
							))}
						</Grid>
					</Grid>

					<Divider />

					<Grid container spacing={16} style={{ marginTop: 8 }}>
						<Grid item lg={12}>
							<Typography variant="h6">
								{`Últimos 20 eventos de ${client.name}`}
							</Typography>

							<Table>
								<TableHead>
									<TableRow>
										<TableCell>#</TableCell>
										<TableCell>Fecha</TableCell>
										<TableCell>Dispositivo</TableCell>
										<TableCell>Estatus alarma</TableCell>
										<TableCell>Operador</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{this.props.events.length === 0 && (
										<TableRow>
											<TableCell colSpan={5} align="center">
												<Typography variant="subtitle1">{'Sin eventos'}</Typography>
											</TableCell>
										</TableRow>
									)}
									{this.props.events.map((row, index) => (
										<TableRow key={row._id} style={{ height: 30 }}>
											<TableCell component="th" scope="row">
												{index + 1}
											</TableCell>
											<TableCell>
												{moment(row.createdAt)
													.local()
													.format('YYYY-MM-DD HH:mm:ss')}
											</TableCell>
											<TableCell>
												{row.device ? row.device.name : '---'}
											</TableCell>
											<TableCell>
												<Chip
													label={row.alarmStatus}
													className={
														row.alarmStatus === 'INTRUSION'
															? classes.chipIntrusion
															: classes.chip
													}
												/>
											</TableCell>
											<TableCell>
												{row.user
													? `${row.user.name} ${row.user.lastName}`
													: ''}
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</Grid>
					</Grid>
				</DialogContent>
			</Fragment>
		)
	}
}

export default withStyles(styles)(ClientsDetailDialog)
