import React, { Component } from 'react'
import {
	Typography,
	DialogTitle,
	DialogContent,
	DialogActions,
	Grid,
	Button,
	IconButton,
	Tooltip,
	TextField
} from '@material-ui/core'
import { FileCopy as FileCopyIcon } from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'
import { generateSTCode } from '../../services/Utils'

const styles = theme => ({
	button: {
		margin: theme.spacing.unit
	},
	leftIcon: {
		marginRight: theme.spacing.unit
	},
	rightIcon: {
		marginLeft: theme.spacing.unit
	},
	iconSmall: {
		fontSize: 20
	},
	container: {
		display: 'flex',
		flexWrap: 'wrap'
	},
	preCode: {
		backgroundColor: '#ececec',
		borderRadius: 5,
		padding: 16,
		display: 'block',
		whiteSpace: 'pre-wrap',
		color: '#585858'
	},
	textField: {
		fontSize: 15,
		overflow: 'hidden'
	}
})

class EquipmentsSTCodeDialog extends Component {
	copyScript = () => {
		const copyText = document.getElementById('st-script')
		copyText.select()
		document.execCommand('copy')
		alert('Texto copiado')
	}

	render() {
		const { classes, equipment } = this.props

		if (!equipment) return

		return (
			<React.Fragment>
				<DialogTitle>Código fuente SmartApp</DialogTitle>
				<DialogContent>
					<Grid container spacing={16}>
						<Grid item lg={12} md={12} sm={12}>
							<Typography variant="body1">
								{
									'Para el uso correcto del monitoreo se deberá copiar y pegar el código siguiente dentro de una SmartApp en el "Smarthings Groovy IDE"'
								}
							</Typography>

							<div style={{ textAlign: 'right' }}>
								<Tooltip title="Copiar código" placement="left">
									<IconButton
										style={{
											padding: 8,
											borderRadius: 5
										}}
										onClick={this.copyScript}
									>
										<FileCopyIcon color="primary" style={{ fontSize: 20 }} />
									</IconButton>
								</Tooltip>
							</div>
							<TextField
								id="st-script"
								defaultValue={generateSTCode(equipment._id)}
								margin="normal"
								InputProps={{
									readOnly: true,
									className: classes.textField
								}}
								variant="filled"
								multiline
								fullWidth
							/>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={() => {
							this.props.onClose && this.props.onClose()
						}}
						color="primary"
					>
						Cerrar
					</Button>
				</DialogActions>
			</React.Fragment>
		)
	}
}

export default withStyles(styles)(EquipmentsSTCodeDialog)
