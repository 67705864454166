import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import configureStore, { history } from './redux';
import { Provider } from 'react-redux';
import { Router } from 'react-router';
import { feathersAuthentication } from './redux/FeathersRedux';

const store = configureStore();

if (localStorage['nast_at']) {
	store.dispatch(feathersAuthentication.authenticate())
}


ReactDOM.render(
	<Provider store={store}>
		<Router history={history}>
			<App />
		</Router>
	</Provider>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
