import io from 'socket.io-client';
import feathers from '@feathersjs/client';
import reduxifyServices from 'feathers-redux';
import { combineReducers } from 'redux'
import reduxifyAuthentication from '../lib/FeathersReduxifyAuthentication';
import Config from '../config';
// Socket.io is exposed as the `io` global.
// const socket = io('http://localhost:3030');
const socket = io(Config.apiUrl);
const app = feathers();

app.configure(feathers.socketio(socket));
app.configure(feathers.authentication({
    storage: window.localStorage,
    storageKey: 'nast_at',
}));

export const services = reduxifyServices(app, [
    'clients', 'users', 'equipments', 'events', 'devices', 'eventobservations'
]);

export const servicesReducer = combineReducers({
    users: services.users.reducer,
    clients: services.clients.reducer,
    equipments: services.equipments.reducer,
    events: services.events.reducer,
    devices: services.devices.reducer,
    eventobservations: services.eventobservations.reducer,
})

const feathersClient = app
export const feathersAuthentication = reduxifyAuthentication(feathersClient);

export default feathersClient