import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'
import {
	Paper,
	Toolbar,
	Typography,
	Dialog,
	MenuItem,
	Grid,
	Button,
	TextField,
	FormControl,
	InputLabel,
	OutlinedInput,
	Snackbar,
} from '@material-ui/core'
import MuiDownshift from 'mui-downshift'
import {
	Save as SaveIcon,
	// Add as AddIcon,
	// Delete as DeleteIcon
} from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import Segment from '../../components/Segment'
import feathersClient, { services } from '../../redux/FeathersRedux'
import { Link as RouterLink } from 'react-router-dom'
import classNames from 'classnames'
import MaskedInput from 'react-text-mask'
import MySnackbarContent from '../../components/MySnackbar'
import EquipmentsSTCodeDialog from './EquipmentsSTCodeDialog';

function TextMaskCustom(props) {
	const { inputRef, ...other } = props

	return (
		<MaskedInput
			{...other}
			ref={ref => {
				inputRef(ref ? ref.inputElement : null)
			}}
			mask={[
				/\w/,
				/\w/,
				':',
				/\w/,
				/\w/,
				':',
				/\w/,
				/\w/,
				':',
				/\w/,
				/\w/,
				':',
				/\w/,
				/\w/,
				':',
				/\w/,
				/\w/
			]}
			// placeholderChar={'\u2000'}
			// showMask
			guide
		/>
	)
}

const styles = theme => ({
	button: {
		margin: theme.spacing.unit
	},
	leftIcon: {
		marginRight: theme.spacing.unit
	},
	rightIcon: {
		marginLeft: theme.spacing.unit
	},
	iconSmall: {
		fontSize: 20
	},
	container: {
		display: 'flex',
		flexWrap: 'wrap'
	},
	preCode: {
		backgroundColor: '#ececec',
		borderRadius: 5,
		padding: 16,
		display: 'block',
		whiteSpace: 'pre-wrap',
		color: '#585858'
	}
})

const EQUIPMENT_TYPES = [
	{
		label: 'Smart Things',
		value: 'SMARTTHINGS'
	},
	{
		label: 'Otros',
		value: 'OTHERS'
	}
]

const EQUIPMENT_STATUS = [
	{
		label: 'Activo',
		value: 'ACTIVE'
	},
	{
		label: 'Pruebas',
		value: 'TEST'
	},
	{
		label: 'En almacén',
		value: 'WAREHOUSE'
	},
	{
		label: 'Deshabilitado',
		value: 'DISABLED'
	}
]

const INITIAL_STATE = {
	client: '',
	equipmentType: '',
	serialNumber: '',
	mac: '',
	deviceId: '',
	status: '',
	operator: '5c6afc2dffd8a8168cbf08b6',
	sensors: []
}

class EquipmentsCreate extends Component {
	state = {
		loading: false,
		clients: [],
		equipment: {
			...INITIAL_STATE
		},
		modalOpen: false,
		modalScriptOpen: false,
		currentSensor: {
			name: '',
			type: '',
			area: ''
		},
		snackbar: {
			open: false,
			message: '',
			variant: 'success'
		}
	}

	/**
	 * Enables Snackbar component which displays API response
	 */
	openSnackbar = (message, variant) => {
		this.setState({
			snackbar: {
				open: true,
				message,
				variant
			}
		})
	}

	/**
	 * Closes Snackbar component
	 */
	closeSnackbar = () => {
		this.setState({
			snackbar: {
				...this.state.snackbar,
				open: false
			}
		})
	}

	getClients = async filter => {
		try {
			const clients = await feathersClient.service('clients').find({
				query: {
					$or: [
						{ name: { $search: filter } },
						{ lastName: { $search: filter } },
						{ clientNumber: { $search: filter } }
					]
				}
			})

			this.setState({
				clients: clients.data.map(c => {
					return {
						label: `${c.name} ${c.lastName}`,
						value: c._id
					}
				})
			})
		} catch (e) {}
	}

	updateEquipmentState = (name, value) => {
		this.setState({
			equipment: {
				...this.state.equipment,
				[name]: value
			}
		})
	}

	updateCurrentSensorState = (name, value) => {
		this.setState({
			currentSensor: {
				...this.state.currentSensor,
				[name]: value
			}
		})
	}

	onClientChange = client => {
		let value = client && client.value ? client.value : ''
		this.updateEquipmentState('client', value)
	}

	handleClose = () => {
		this.setState({
			modalOpen: false
		})
	}

	handleModalScriptClose = () => {
		this.setState({
			modalScriptOpen: false
		})
	}

	addSensor = () => {
		const sensors = this.state.equipment.sensors.concat(
			this.state.currentSensor
		)

		this.setState({
			equipment: {
				...this.state.equipment,
				sensors
			},
			modalOpen: false,
			currentSensor: {
				name: '',
				type: '',
				area: ''
			}
		})
	}

	removeSensor = index => {
		const sensors = [...this.state.equipment.sensors]
		sensors.splice(index, 1)

		this.setState({
			equipment: {
				...this.state.equipment,
				sensors
			}
		})
	}

	checkDisabled = () => {
		const { equipment } = this.state
		return (
			!equipment.client ||
			!equipment.equipmentType ||
			!equipment.mac ||
			!equipment.serialNumber ||
			!equipment.status
		)
	}

	openModalScript = () => {
		this.setState({ modalScriptOpen: true })
	}

	save = () => {
		this.setState({ loading: true })
		this.props
			.equipmentsCreate(this.state.equipment)
			.then(res => {
				this.openSnackbar('Guardo correctamente', 'success')
				this.setState({ equipment: INITIAL_STATE, loading: false })
				this.openModalScript()
			})
			.catch(err => {
				this.openSnackbar('Hubo un error al guardar', 'error')
				this.setState({ loading: false })
			})
	}

	render() {
		const { classes } = this.props
		const { equipment } = this.state

		return (
			<div>
				<Toolbar disableGutters>
					<Typography variant="h6" style={{ flex: 1 }}>
						<Link component={RouterLink} to="/equipments">
							{'Equipos'}
						</Link>
						{' > Creación'}
					</Typography>

					<Button
						onClick={this.save}
						size="small"
						color="primary"
						variant="contained"
						disabled={this.checkDisabled()}
					>
						<SaveIcon
							className={classNames(classes.leftIcon, classes.iconSmall)}
						/>
						{'Guardar'}
					</Button>
				</Toolbar>
				<Segment loading={this.state.loading}>
					<Grid container spacing={16}>
						<Grid item lg={12} md={12} sm={12}>
							<Toolbar disableGutters>
								<Typography variant="subtitle1">
									{'Información general'}
								</Typography>
							</Toolbar>
							<Paper style={{ padding: 16 }}>
								<Grid container spacing={16}>
									<Grid item lg={12} md={12} sm={12}>
										<MuiDownshift
											items={this.state.clients}
											variant="outlined"
											getInputProps={() => {
												return {
													fullWidth: true,
													label: 'Cliente'
												}
											}}
											onInputValueChange={this.getClients}
											onChange={this.onClientChange}
										/>
									</Grid>

									<Grid item lg={12} md={12} sm={12}>
										<TextField
											select
											label="Tipo de equipo"
											variant="outlined"
											fullWidth
											margin="none"
											value={equipment.equipmentType}
											onChange={event => {
												this.updateEquipmentState(
													'equipmentType',
													event.target.value
												)
											}}
										>
											{EQUIPMENT_TYPES.map(option => (
												<MenuItem key={option.value} value={option.value}>
													{option.label}
												</MenuItem>
											))}
										</TextField>
									</Grid>

									<Grid item lg={6} md={6} sm={12}>
										<TextField
											label="#Serial"
											variant="outlined"
											fullWidth
											margin="none"
											value={equipment.serialNumber}
											onChange={event => {
												this.updateEquipmentState(
													'serialNumber',
													event.target.value
												)
											}}
										/>
									</Grid>

									<Grid item lg={6} md={6} sm={12}>
										<FormControl
											className={classes.formControl}
											fullWidth
											variant="outlined"
										>
											<InputLabel
												htmlFor="component-outlined"
												ref={ref => {
													this.labelRef = ReactDOM.findDOMNode(ref)
												}}
											>
												{'Dirección MAC'}
											</InputLabel>
											<OutlinedInput
												id="component-outlined"
												inputComponent={TextMaskCustom}
												labelWidth={
													this.labelRef ? this.labelRef.offsetWidth : 0
												}
												value={equipment.mac}
												onChange={event => {
													this.updateEquipmentState('mac', event.target.value)
												}}
											/>
										</FormControl>
									</Grid>

									<Grid item lg={6} md={6} sm={12}>
										<TextField
											label="Id de Dispositivo"
											variant="outlined"
											fullWidth
											margin="none"
											value={equipment.deviceId}
											onChange={event => {
												this.updateEquipmentState(
													'deviceId',
													event.target.value
												)
											}}
										/>
									</Grid>

									<Grid item lg={6} md={12} sm={12}>
										<TextField
											select
											label="Estatus"
											variant="outlined"
											fullWidth
											margin="none"
											value={equipment.status}
											onChange={event => {
												this.updateEquipmentState('status', event.target.value)
											}}
										>
											{EQUIPMENT_STATUS.map(option => (
												<MenuItem key={option.value} value={option.value}>
													{option.label}
												</MenuItem>
											))}
										</TextField>
									</Grid>
								</Grid>
							</Paper>
						</Grid>
					</Grid>
				</Segment>

				<Dialog
					open={this.state.modalScriptOpen}
					onClose={this.handleModalScriptClose}
					maxWidth="md"
				>
					<EquipmentsSTCodeDialog equipment={this.props.equipments.data} onClose={this.handleModalScriptClose} />
				</Dialog>
				
				<Snackbar
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'right'
					}}
					open={this.state.snackbar.open}
					autoHideDuration={10000}
					onClose={this.closeSnackbar}
				>
					<MySnackbarContent
						message={this.state.snackbar.message}
						variant={this.state.snackbar.variant}
						onClose={this.closeSnackbar}
					/>
				</Snackbar>
			</div>
		)
	}
}

const mapStateToProps = state => ({
	equipments: state.services.equipments
})

const mapDispatchToProps = dispatch => ({
	equipmentsFind: (query = {}) => dispatch(services.equipments.find(query)),
	store: data => dispatch(services.equipments.store(data)),
	equipmentsCreate: data => dispatch(services.equipments.create(data))
})

export default withStyles(styles)(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(EquipmentsCreate)
)
