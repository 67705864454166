// @flow
import * as React from 'react'

type Props = {
    authorizedRoles: Array<string> | string,
    role: String,
}

class AuthorizedComponent extends React.PureComponent<Props> {

    render() {
        if (this.props.authorizedRoles.find((f) => f.toLowerCase() === this.props.role.toLowerCase())) {
            return this.props.children
        }

        return null
    }
}

export default AuthorizedComponent