import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
	Paper,
	Toolbar,
	Typography,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	IconButton,
	TablePagination,
	Dialog,
	Slide,
} from '@material-ui/core'
import {
	AddCircle as AddCircleIcon,
	Refresh as RefreshIcon,
	Create as CreateIcon,
	Info as InfoIcon,
} from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import moment from 'moment'
import Segment from '../../components/Segment'
import feathersClient, { services } from '../../redux/FeathersRedux'
import ClientsDetailDialog from './ClientsDetailDialog';
import ClientsListToolbar from './ClientsListToolbar';

const Transition = props => {
	return <Slide direction="up" {...props} />
}

const styles = () => ({
	iconSmall: {
		fontSize: 18,
	},
})

const INITIAL_DATA = {
	clientNumber: '',
	name: '',
	lastName: '',
	address: '',
	city: '',
	suburb: '',
	zipcode: '',
	betweenStreets: '',
	residenceType: '',
	keyword: '',
	stUser: '',
	stPassword: '',
	stToken: '',
	contacts: [],
}

// let ROWS_PER_PAGE = 10

class ClientsList extends Component {

	state = {
		dialogOpen: false,
		client: INITIAL_DATA,
		rowsPerPage: 10,
		events: [],
		query: {},
	}

	componentDidMount() {
        if (!this.props.clients.store) {
            this.getData(0)
        }
    }

    getData = (page) => {
		this.props.store({ page })

		const query = this.state.query || {}

        setTimeout(() => {
            this.props.clientsFind({
                query: {
                    $skip: this.state.rowsPerPage * page,
                    $limit: this.state.rowsPerPage,
                    $sort: {
                        createdAt: -1,
					},
					$populate: {
						path: 'equipment',
						select: ['client']
					},
					...query,
                },
            })
        }, 50)
	}

	refreshData = () => {
		this.getData(
			this.props.clients.queryResult.skip / this.state.rowsPerPage,
		)
	}

	onDialogClose = () => {
		this.setState({
			dialogOpen: false,
			client: INITIAL_DATA,
		})
	}

    onChangePage = (e, page) => {
        console.log(page)
		this.getData(page)
	}

	handleChangeRowsPerPage = (e, rows) => {
		this.setState({ rowsPerPage:rows.key })
		this.getData(0)
	}

	showClientDetails = async (row) => {

		if (row.equipment) {
			try {

				this.setState({
					client: row,
					dialogOpen: true,
				})

				const response = await feathersClient.service('events').find({
					query: {
						equipment: row.equipment.id,
						$limit: 20,
						$sort: {
							createdAt: -1
						},
						$populate: ['device', 'user']
					}
				})

				this.setState({
					events: response.data
				})

			} catch (error) {
				alert('error')
			} 

		} else {
			this.setState({
				client: row,
				dialogOpen: true,
				events: []
			})

		}

	}

	renderDialogClientDetails = () => {
		const { client } = this.state
		return (
			<Dialog
				open={this.state.dialogOpen}
				onClose={this.onDialogClose}
				fullScreen
				TransitionComponent={Transition}
			>
				<ClientsDetailDialog client={client} events={this.state.events} onClose={this.onDialogClose} />
			</Dialog>
		)
	}

    render() {
        const { classes } = this.props

        return (
            <Paper>
				<ClientsListToolbar
					onFilter={query => {
						this.setState({ query }, this.getData)
					}}
					refreshData={this.refreshData}
				/>

				<Segment loading={this.props.clients.isLoading}>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>No. cliente</TableCell>
								<TableCell>Nombres</TableCell>
								<TableCell>Apellido</TableCell>
								<TableCell>Ciudad</TableCell>
								<TableCell>Colonia</TableCell>
								<TableCell>Tipo residencia</TableCell>
								<TableCell>Clave</TableCell>
								<TableCell>Fecha creación</TableCell>
								<TableCell>Acciones</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{this.props.clients.queryResult.data.map(row => (
								<TableRow key={row._id}>
									<TableCell component="th" scope="row">
										{row.clientNumber}
									</TableCell>
									<TableCell>
										{row.name}
									</TableCell>
									<TableCell>{row.lastName}</TableCell>
									<TableCell>{row.city}</TableCell>
									<TableCell>{row.suburb}</TableCell>
									<TableCell>{row.residenceType}</TableCell>
									<TableCell>{row.keyword}</TableCell>
									<TableCell>
										{moment(row.createdAt).format('DD/MM/YYYY HH:mm:ss')}
									</TableCell>
									<TableCell style={{ display: 'flex' }}>
										<IconButton title="See details" onClick={() => { this.showClientDetails(row) }}>
											<InfoIcon className={classes.iconSmall} />
										</IconButton>
										<IconButton
											color="secondary"
											title="Edit"
											component={Link}
											to={`clients/${row._id}/edit`}
										>
											<CreateIcon className={classes.iconSmall} />
										</IconButton>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>

					<TablePagination
						rowsPerPageOptions={[5, 10, 25]}
						component="div"
						count={this.props.clients.queryResult.total}
						rowsPerPage={this.state.rowsPerPage}
						page={(this.props.clients.queryResult.skip / this.state.rowsPerPage)}
						backIconButtonProps={{
							'aria-label': 'Previous Page',
						}}
						nextIconButtonProps={{
							'aria-label': 'Next Page',
						}}
						onChangePage={this.onChangePage}
						onChangeRowsPerPage={this.handleChangeRowsPerPage}
						labelRowsPerPage="Registros por página:"
					/>
                </Segment>
				<this.renderDialogClientDetails />
            </Paper>
        )
    }
}

const mapStateToProps = state => ({
    clients: state.services.clients,
})

const mapDispatchToProps = (dispatch) => ({
    clientsFind: (query = {}) => dispatch(services.clients.find(query)),
    store: data => dispatch(services.clients.store(data)),
})

export default withStyles(styles)(
    connect(mapStateToProps, mapDispatchToProps)(ClientsList)
)