import React, { Component } from 'react';
import classNames from 'classnames';
import {
	AppBar,
	Toolbar,
	Drawer,
	Divider,
	IconButton,
	Typography,
	Badge
} from '@material-ui/core/';
import { withStyles } from '@material-ui/core/styles';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MenuListItems from './MenuListItems'
import { connect } from 'react-redux'
import { feathersAuthentication } from '../redux/FeathersRedux';

const drawerWidth = 240;

const styles = theme => ({
	root: {
		display: 'flex'
	},
	toolbar: {
		paddingRight: 24 // keep right padding when drawer closed
	},
	toolbarIcon: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		padding: '0 8px',
		...theme.mixins.toolbar,
		color: 'white'
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen
		})
	},
	appBarShift: {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen
		})
	},
	menuButton: {
		marginLeft: 12,
		marginRight: 36,
	},
	menuButtonHidden: {
		display: 'none'
	},
	title: {
		flexGrow: 1
	},
	drawerPaper: {
		position: 'relative',
		whiteSpace: 'nowrap',
		width: drawerWidth,
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen
		}),
		minHeight: '100vh',
		backgroundColor: '#2f363b'
	},
	drawerPaperClose: {
		overflowX: 'hidden',
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen
		}),
		width: 0, // theme.spacing.unit * 7,
		[theme.breakpoints.up('sm')]: {
			width: 0 // theme.spacing.unit * 9,
		},
		minHeight: '100vh'
	},
	appBarSpacer: theme.mixins.toolbar,
	content: {
		flexGrow: 1,
		padding: theme.spacing.unit * 3,
		height: '100vh',
		overflow: 'auto'
	},
	chartContainer: {
		marginLeft: -22
	},
	tableContainer: {
		height: 320
	},
	h5: {
		marginBottom: theme.spacing.unit * 2
	},
	whiteFont: {
		color: 'rgba(255,255,255,0.85)'
	},
	whiteDivider: {
		backgroundColor: 'rgba(255,255,255,0.2)'
	},
	drawer: {
		// position: 'fixed'
	},
	avatar: {
		height: 40,
		marginRight: 8
	}
});

class LayoutContainer extends Component {
	state = {
		open: true
	};

	handleDrawerOpen = () => {
		this.setState({ open: true });
	};

	handleDrawerClose = () => {
		this.setState({ open: false });
	};

	logOut = () => {
		this.props.logOut()
	}

	render() {
		const { classes } = this.props;

		return (
			<div>
				<AppBar
					className={classNames(
						classes.appBar,
						this.state.open && classes.appBarShift
					)}
				>
					<Toolbar
						disableGutters={!this.state.open}
						className={classes.toolbar}
					>
						<IconButton
							color="inherit"
							aria-label="Open drawer"
							onClick={this.handleDrawerOpen}
							className={classNames(
								classes.menuButton,
								this.state.open && classes.menuButtonHidden
							)}
						>
							<MenuIcon />
						</IconButton>

						<img
							alt="logo"
							className={classes.avatar}
							src={require('../assets/images/logo.png')}
						/>
						<Typography
							component="h2"
							variant="h6"
							color="inherit"
							noWrap
							className={classes.title}
						>
							{'Conexión Alarmas'}
						</Typography>
						{/*<IconButton color="inherit">
							<Badge badgeContent={4} color="secondary">
								<NotificationsIcon />
							</Badge>
							</IconButton>*/}
					</Toolbar>
				</AppBar>
				<Drawer
					variant="permanent"
					classes={{
						paper: classNames(
							classes.drawerPaper,
							!this.state.open && classes.drawerPaperClose
						)
					}}
					open
					className={classes.drawer}
				>
					<div className={classes.toolbarIcon}>
						<IconButton onClick={this.handleDrawerClose}>
							<ChevronLeftIcon className={classes.whiteFont} />
						</IconButton>
					</div>
					<Divider className={classes.whiteDivider} />

					<MenuListItems user={this.props.user } logOut={this.logOut} />
					
				</Drawer>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	user: state.auth.user
})

const mapStateToDispatch = dispatch => ({
	logOut: () => dispatch(feathersAuthentication.logout())
})

export default withStyles(styles)(connect(mapStateToProps, mapStateToDispatch)(LayoutContainer));
