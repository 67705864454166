import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
	Paper,
	Toolbar,
	Typography,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	IconButton,
	TablePagination,
    Switch,
    Tooltip,
} from '@material-ui/core'
import {
	AddCircle as AddCircleIcon,
	Refresh as RefreshIcon,
	Create as CreateIcon
	// Info as InfoIcon,
} from '@material-ui/icons'
import { Link } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import Segment from '../../components/Segment'
import { services } from '../../redux/FeathersRedux'
import UsersListToolbar from './UsersListToolbar';

const styles = () => ({
	iconSmall: {
		fontSize: 18
	}
})

// const ROWS_PER_PAGE = 10

class UsersList extends Component {
	state = {
		rowsPerPage: 10,
		query: {}		
	}

	componentDidMount() {
		// if (!this.props.users.store) {
		this.getData(0)
		// }
	}

	getData = (page = 0) => {
		this.props.store({ page })

		const query = this.state.query || {}

		setTimeout(() => {
			this.props.usersFind({
				query: {
					$skip: this.state.rowsPerPage * this.props.users.store.page,
					$limit: this.state.rowsPerPage,
					$sort: {
						createdAt: -1
					},
					...query,
				}
			})
		}, 50)
	}

	refreshData = () => {
		this.getData(
			this.props.users.queryResult.skip / this.state.rowsPerPage,
		)
	}

	onChangePage = (e, page) => {
		this.getData(page)
	}

	handleChangeRowsPerPage = (e, rows) => {
		this.setState({ rowsPerPage: rows.key })
		this.getData(0)
	}

	showClientDetails = row => {
		this.setState({
			client: row,
			dialogOpen: true
		})
	}

	toggleActive = user => async () => {
		const { patch } = this.props

		await patch(user._id, {
			active: !user.active
		})

		this.getData(this.props.users.store.page)
	}

	render() {
		const { classes } = this.props

		return (
			<Paper>
				<UsersListToolbar
					onFilter={query => {
						this.setState({ query }, this.getData)
					}}
					refreshData={this.refreshData}
				/>

				<Segment loading={this.props.users.isLoading}>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>Nombres</TableCell>
								<TableCell>Apellido</TableCell>
								<TableCell>Rol</TableCell>
								<TableCell>Correo</TableCell>
								<TableCell>Activo</TableCell>
								<TableCell>Acciones</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{this.props.users.queryResult.data.map(row => (
								<TableRow key={row._id}>
									<TableCell component="th" scope="row">
										{row.name}
									</TableCell>
									<TableCell>{row.lastName}</TableCell>
									<TableCell>{row.role}</TableCell>
									<TableCell>{row.email}</TableCell>
									<TableCell>
										<Switch
											checked={row.active}
											onChange={this.toggleActive(row)}
										/>
									</TableCell>
									<TableCell>
										<Tooltip title="Editar">
											<IconButton
												color="secondary"
												component={Link}
												to={`users/${row._id}/edit`}
											>
												<CreateIcon className={classes.iconSmall} />
											</IconButton>
										</Tooltip>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>

					<TablePagination
						rowsPerPageOptions={[5, 10, 25]}
						component="div"
						count={this.props.users.queryResult.total}
						rowsPerPage={this.state.rowsPerPage}
						page={this.props.users.queryResult.skip / this.state.rowsPerPage}
						backIconButtonProps={{
							'aria-label': 'Previous Page'
						}}
						nextIconButtonProps={{
							'aria-label': 'Next Page'
						}}
						onChangePage={this.onChangePage}
						onChangeRowsPerPage={this.handleChangeRowsPerPage}
						labelRowsPerPage="Registros por página:"
					/>
				</Segment>
			</Paper>
		)
	}
}

const mapStateToProps = state => ({
	users: state.services.users
})

const mapDispatchToProps = dispatch => ({
	usersFind: (query = {}) => dispatch(services.users.find(query)),
	store: data => dispatch(services.users.store(data)),
	patch: (id, data) => dispatch(services.users.patch(id, data))
})

export default withStyles(styles)(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(UsersList)
)
