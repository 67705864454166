// @flow
import * as React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { getUserHome } from '../Auth';

type Props = {
	authorizedRoles: Array<string> | string,
	component: React.ReactNode
}

class AuthorizedRoute extends React.PureComponent<Props> {
	constructor(props) {
		super(props)

		this.isAuthorized = this.props.authorizedRoles.find(
			f => f.toLowerCase() === this.props.user.role.toLowerCase()
		)

		this.userHome = getUserHome(this.props.user)
	}

	render() {
		const { component: Component, authorizedRoles, user, ...rest } = this.props
		return (
			<Route
				{...rest}
				render={props => {
					if (this.isAuthorized) return <Component {...props} />

					return <Redirect to={this.userHome} />
				}}
			/>
		)
	}
}

const mapStateToProps = state => ({
	user: state.auth.user
})

export default connect(mapStateToProps)(AuthorizedRoute)
