import React, { Component } from 'react'
import {
	Toolbar,
	Typography,
	IconButton,
	Popover,
	TextField,
	MenuItem,
	Button,
	Grid,
	InputAdornment,
	Chip
} from '@material-ui/core'
import {
	Clear,
	ArrowDropUp,
	ArrowDropDown,
	AddCircle as AddCircleIcon,
	Refresh as RefreshIcon,
	FilterList as FilterIcon,
	Info as InfoIcon
} from '@material-ui/icons'
// import { DateRangePicker } from 'react-dates'
import { Link } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import feathersClient from '../../redux/FeathersRedux'
import MuiDownshift from 'mui-downshift'
import moment from 'moment'
import 'moment/locale/es'

moment.locale('es')

const styles = theme => ({
	iconSmall: {
		fontSize: 20
	},
	actionButton: {
		padding: theme.spacing.unit / 2
	},
	chip: {
		marginRight: 8
	}
})

const INITIAL_FILTERS = {
	deviceId: {
		label: '',
		value: '',
	},
	serialNumber: {
		label: '',
		value: '',
		type: 'search'
	},
	mac: {
		label: '',
		value: '',
		type: 'search'
	},
	client: {
		label: '',
		value: ''
	}
}

class EquipmentsListToolbar extends Component {
	state = {
		filters: INITIAL_FILTERS,
		appliedFilters: {},
		clients: [],
		focusedInput: null,
		dates: {
			startDate: null,
			endDate: null
		}
	}

	updateFilters = (name, value, doFilter = false) => {
		let newValue = value

		if (typeof value === 'string') {
			newValue = {
				label: value,
				value
			}
		}

		this.setState(
			{
				filters: {
					...this.state.filters,
					[name]: {
						...this.state.filters[name],
						...newValue
					}
				}
			},
			() => {
				if (doFilter) {
					this.doFilter()
				}
			}
		)
	}

	clearFilters = () => {
		this.setState(
			{
				filters: INITIAL_FILTERS
			},
			this.doFilter
		)
	}

	getClients = async query => {
		try {
			const clients = await feathersClient.service('clients').find({
				query: {
					$or: [
						{ name: { $search: query } },
						{ lastName: { $search: query } },
						{ clientNumber: { $search: query } }
					]
				}
			})

			this.setState({
				clients: clients.data.map(c => {
					return {
						label: `${c.name} ${c.lastName}`,
						value: c._id
					}
				})
			})
		} catch (e) {}
	}

	onClientChange = client => {
		this.updateFilters('client', client)
	}

	doFilter = () => {
		this.setState(
			{
				appliedFilters: {
					...this.state.filters,
					createdAt: {
						...this.state.dates
					}
				}
			},
			() => {
				this.props.onFilter && this.props.onFilter(this.getQueryForFilters())
			}
		)
	}

	getQueryForFilters = () => {
		let query = {}
		const { appliedFilters } = this.state

		Object.keys(appliedFilters).forEach(f => {
			if (
				f === 'createdAt' &&
				appliedFilters.createdAt.startDate &&
				appliedFilters.createdAt.endDate
			) {
				query['createdAt'] = {
					$gte: appliedFilters.createdAt.startDate.toDate(),
					$lte: appliedFilters.createdAt.endDate.toDate()
				}
			}

			if (f && appliedFilters[f].value) {
				if (appliedFilters[f].type && appliedFilters[f].type === 'search') {
					query[f] = {
						$search: appliedFilters[f].value
					}
				} else {
					query[f] = appliedFilters[f].value
				}
			}
		})

		return query
	}

	refreshData = () => {
		this.props.refreshData && this.props.refreshData()
	}

	render() {
		const { classes } = this.props
		const { anchorEl } = this.state
		const open = Boolean(anchorEl)

		return (
			<React.Fragment>
				<Toolbar>
					<Typography variant="h6" style={{ flex: 1 }}>
						{'Lista de equipos'}
					</Typography>
					<IconButton
						color="primary"
						onClick={event => {
							this.setState({
								anchorEl: event.currentTarget
							})
						}}
					>
						<FilterIcon />
					</IconButton>
					<Popover
						id="simple-popper"
						PaperProps={{
							style: {
								overflow: 'visible'
							}
						}}
						open={open}
						anchorEl={anchorEl}
						onClose={() => {
							this.setState({
								anchorEl: null
							})
						}}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'center'
						}}
						transformOrigin={{
							vertical: 'top',
							horizontal: 'center'
						}}
					>
						<div style={{ padding: '0 16px 16px', minWidth: 500 }}>
							<form
								onSubmit={e => {
									e.preventDefault()

									this.doFilter()
								}}
							>
								<div style={{ textAlign: 'center', margin: '16px 0' }}>
									<Button
										type="submit"
										variant="text"
										style={{ marginRight: 5 }}
										// onClick={this.doFilter}
									>
										Filtrar
									</Button>
									<Button
										variant="text"
										style={{ fontSize: 12 }}
										onClick={this.clearFilters}
									>
										Reiniciar
									</Button>
								</div>

								<Grid container spacing={16}>
									<Grid item lg={6}>
										<MuiDownshift
											items={this.state.clients}
											variant="outlined"
											getInputProps={downshiftProps => {
												return {
													// fullWidth: true,
													label: 'Cliente',
													endAdornment: (
														<InputAdornment position="end">
															{!!downshiftProps.selectedItem && (
																<IconButton
																	className={classes.actionButton}
																	onClick={downshiftProps.clearSelection}
																	aria-label="Clear selection"
																>
																	<Clear />
																</IconButton>
															)}
															<IconButton
																className={classes.actionButton}
																onClick={downshiftProps.handleToggleMenu}
																aria-label="Toggle menu open"
															>
																{downshiftProps.isOpen ? (
																	<ArrowDropUp />
																) : (
																	<ArrowDropDown />
																)}
															</IconButton>
														</InputAdornment>
													)
												}
											}}
											onInputValueChange={this.getClients}
											onChange={this.onClientChange}
											// inputValue={this.state.filters.client.label}
											selectedItem={
												this.state.filters.client.label
													? this.state.filters.client
													: null
											}
										/>
									</Grid>
									<Grid item lg={6}>
										<TextField
											label="ID Dispositivo"
											value={this.state.filters.deviceId.value}
											onChange={e => {
												this.updateFilters('deviceId', e.target.value)
											}}
											margin="none"
											variant="outlined"
											fullWidth
										/>
									</Grid>
									<Grid item lg={6}>
										<TextField
											label="No. Serial"
											value={this.state.filters.serialNumber.value}
											onChange={e => {
												this.updateFilters('serialNumber', e.target.value)
											}}
											margin="none"
											variant="outlined"
											fullWidth
										/>
									</Grid>
									<Grid item lg={6}>
										<TextField
											label="MAC"
											value={this.state.filters.mac.value}
											onChange={e => {
												this.updateFilters('mac', e.target.value)
											}}
											margin="none"
											variant="outlined"
											fullWidth
										/>
									</Grid>
								</Grid>
							</form>
						</div>
					</Popover>
					<IconButton color="primary" onClick={this.refreshData}>
						<RefreshIcon />
					</IconButton>

					<IconButton component={Link} to="/equipments/create" color="secondary">
						<AddCircleIcon />
					</IconButton>
				</Toolbar>
				<div style={{ padding: '0 16px' }}>
					{Object.keys(this.state.appliedFilters).map(f => {
						if (
							f === 'createdAt' &&
							this.state.appliedFilters.createdAt.startDate &&
							this.state.appliedFilters.createdAt.endDate
						) {
							return (
								<Chip
									label={`${this.state.appliedFilters.createdAt.startDate.format(
										'DD/MM/YYYY'
									)} -> ${this.state.appliedFilters.createdAt.endDate.format(
										'DD/MM/YYYY'
									)}`}
									className={classes.chip}
									onDelete={() => {
										this.setState(
											{
												dates: {
													startDate: null,
													endDate: null
												},
												filters: {
													...this.state.filters,
													createdAt: null
												}
											},
											this.doFilter
										)
									}}
								/>
							)
						}

						if (this.state.appliedFilters[f].value) {
							return (
								<Chip
									label={this.state.appliedFilters[f].label}
									className={classes.chip}
									onDelete={() => {
										this.updateFilters(f, { label: '', value: '' }, true)
									}}
								/>
							)
						}

						return null
					})}
				</div>
			</React.Fragment>
		)
	}
}

export default withStyles(styles)(EquipmentsListToolbar)
