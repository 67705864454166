import React, { /*Component */} from 'react';
// import PropTypes from 'prop-types';
import { Button, CircularProgress } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = theme => ({
	button: {
		margin: theme.spacing.unit * 3
	}
});

const LoadingButton = props => {
    const { classes, loading, children, loadingProps = {}, ...other } = props;

	if (loading) {
		return (
			<Button className={classes.button} {...other}>
				<CircularProgress {...loadingProps} />
			</Button>
		);
	}
	return (
		<Button className={classes.button} {...other}>
			{children}
		</Button>
	);
}

export default withStyles(styles)(LoadingButton)