import React, { PureComponent } from 'react'
import {
	Paper,
	Typography,
    Grid,
} from '@material-ui/core';
import {
	Info as InfoIcon
} from '@material-ui/icons';
import moment from 'moment';
import { red, green } from '@material-ui/core/colors'

class MonitoringItem extends PureComponent {

    assignAndShowEvent = () => {
        const { event, assignAndShowEvent } = this.props
        assignAndShowEvent && assignAndShowEvent(event)
    }

    onInfoButtonClick = () => {
        const { event, onInfoButtonClick } = this.props

        onInfoButtonClick && onInfoButtonClick(event)
    }

    render() {
        const { event } = this.props

        return (
            <Paper key={event._id} style={{ marginBottom: 20, backgroundColor: event.resolved === true ? green[600] : red[600], cursor: 'pointer' }}>
                <Grid container spacing={16} style={{ padding: '0 16px' }} onDoubleClick={this.assignAndShowEvent}>

                    <Grid item md>
                        <Typography style={{ color: 'white' }}>{event.equipment.client.clientNumber}</Typography>
                    </Grid>

                    <Grid item md>
                        <Typography style={{ color: 'white' }}>{event.equipment.client.name} {event.equipment.client.lastName}</Typography>
                    </Grid>

                    <Grid item md>
                        <Typography style={{ color: 'white' }}>{event.device ? event.device.name : '---'}</Typography>
                    </Grid>

                    <Grid item md>
                        <Typography style={{ color: 'white' }}>{event.device ? event.device.type : '---'}</Typography>
                    </Grid>

                    <Grid item md>
                        <Typography style={{ color: 'white' }}>{event.device ? event.device.group : '---'}</Typography>
                    </Grid>

                    <Grid item md>
                        <Typography style={{ color: 'white' }}>{event.alarmStatus}</Typography>
                    </Grid>

                    <Grid item md>
                        <Typography style={{ color: 'white' }}>{moment(event.createdAt).format('DD/MM/YYYY HH:mm:ss')}</Typography>
                    </Grid>
                    
                    <Grid item md>
                        <Typography style={{ color: 'white' }}>{event.user ? `${event.user.name} ${event.user.lastName}` : ''}</Typography>
                    </Grid>

                    <Grid item md={1} >
                        <InfoIcon onClick={this.onInfoButtonClick}  />
                        {/* <IconButton color="primary">
                        </IconButton> */}
                        {/* <IconButton color="primary">
                            <AddCircleIcon/>
                        </IconButton> */}
                    </Grid>

                </Grid>
            </Paper>
        );
    }
}

export default MonitoringItem