import { createBrowserHistory } from 'history';
import createStore from './CreateStore';
import reducers from './Reducers';

export const history = process.env.NODE_ENV === 'production' ? createBrowserHistory({
	// basename: '/nast/web'
}) : createBrowserHistory();

export default () => {
	const store = createStore(reducers);

	if (module.hot) {
		module.hot.accept('./Reducers', () => {
			// const nextRootReducer = require('./Reducers').default;
			store.replaceReducer(reducers);
		});
	}

	return store;
};
