import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
	Paper,
	Toolbar,
	Typography,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	IconButton,
	TablePagination,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button
} from '@material-ui/core'
import {
	AddCircle as AddCircleIcon,
	Refresh as RefreshIcon,
	Create as CreateIcon,
	// CheckCircle as CheckCircleIcon,
	Info as InfoIcon
} from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import moment from 'moment'
import Segment from '../../components/Segment'
import feathersClient, { services } from '../../redux/FeathersRedux'
import EquipmentsListToolbar from './EquipmentsListToolbar'

const styles = () => ({
	iconSmall: {
		fontSize: 18
	}
})

// const ROWS_PER_PAGE = 10

class EquipmentsList extends Component {
	state = {
		dataInDetails: [],
		rowsPerPage: 10,
		devices: [],
		query: {},
		current: null
	}

	componentDidMount() {
		if (!this.props.equipments.store) {
			this.getData(0)
		}
	}

	getDevices = async equipmentId => {
		try {
			const devices = await feathersClient.service('devices').find({
				query: {
					equipmentId,
					$limit: 999
				}
			})

			return devices
		} catch (e) {}
	}

	getData = page => {
		this.props.store({ page })

		const query = this.state.query || {}

		setTimeout(() => {
			this.props.equipmentsFind({
				query: {
					$skip: this.state.rowsPerPage * page,
					$limit: this.state.rowsPerPage,
					$sort: {
						createdAt: -1
					},
					$populate: [
						{
							path: 'client',
							select: ['name', 'lastName', 'clientNumber']
						},
						{
							path: 'operator',
							select: ['name', 'lastName']
						},
						{
							path: 'devices'
						}
					],
					...query
				}
			})
		}, 50)
	}

	refreshData = () => {
		this.getData(
			this.props.equipments.queryResult.skip / this.state.rowsPerPage,
		)
	}

	onChangePage = (e, page) => {
		this.getData(page)
	}

	handleChangeRowsPerPage = (e, rows) => {
		this.setState({ rowsPerPage: rows.key })
		this.getData(0)
	}

	onDialogClose = () => {
		this.setState({
			dialogOpen: false,
			// current: null
		})
	}

	openModalDetails = async equipment => {
		const equipmentId = equipment._id
		const devices = await this.getDevices(equipmentId)
		this.setState({
			dialogOpen: true,
			devices: devices.data,
			current: equipment
		})
	}

	onRefresh = () => {
		this.getData(
			this.props.equipments.queryResult.skip / this.state.rowsPerPage
		)
	}

	render() {
		const { classes } = this.props

		return (
			<Paper>
				<EquipmentsListToolbar
					onFilter={query => {
						this.setState({ query }, this.getData)
					}}
					refreshData={this.refreshData}
				/>

				<Segment loading={this.props.equipments.isLoading}>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>#Serial</TableCell>
								<TableCell>MAC</TableCell>
								<TableCell>Tipo</TableCell>
								<TableCell>No. cliente</TableCell>
								<TableCell>Cliente</TableCell>
								<TableCell>Operador</TableCell>
								<TableCell>Fecha creación</TableCell>
								<TableCell>Estatus</TableCell>
								<TableCell />
							</TableRow>
						</TableHead>
						<TableBody>
							{this.props.equipments.queryResult &&
								this.props.equipments.queryResult.data.map(row => (
									<TableRow key={row._id}>
										<TableCell>{row.serialNumber}</TableCell>
										<TableCell>{row.mac}</TableCell>
										<TableCell>{row.equipmentType}</TableCell>
										<TableCell>{row.client.clientNumber}</TableCell>
										<TableCell>{row.client.name}</TableCell>
										<TableCell>{row.operator.name}</TableCell>
										<TableCell>
											{moment(row.createdAt).format('DD/MM/YYYY HH:mm:ss')}
										</TableCell>
										<TableCell>{row.status}</TableCell>
										<TableCell style={{ display: 'flex' }}>
											<IconButton
												title="See details"
												onClick={() => {
													this.openModalDetails(row)
												}}
											>
												<InfoIcon className={classes.iconSmall} />
											</IconButton>
											<IconButton
												color="secondary"
												title="Edit"
												component={Link}
												to={`equipments/${row._id}/edit`}
											>
												<CreateIcon className={classes.iconSmall} />
											</IconButton>
										</TableCell>
									</TableRow>
								))}
						</TableBody>
					</Table>

					<TablePagination
						rowsPerPageOptions={[5, 10, 25]}
						component="div"
						count={this.props.equipments.queryResult.total}
						rowsPerPage={this.state.rowsPerPage}
						page={
							this.props.equipments.queryResult.skip / this.state.rowsPerPage
						}
						backIconButtonProps={{
							'aria-label': 'Previous Page'
						}}
						nextIconButtonProps={{
							'aria-label': 'Next Page'
						}}
						onChangePage={this.onChangePage}
						onChangeRowsPerPage={this.handleChangeRowsPerPage}
						labelRowsPerPage="Registros por página:"
					/>
				</Segment>

				<Dialog
					open={this.state.dialogOpen}
					onClose={this.onClose}
					maxWidth="md"
				>
					<DialogTitle>Sensores</DialogTitle>
					<DialogContent>
						{this.state.current ? (
							<React.Fragment>
								<Typography>{`ID Dispositivo: ${this.state.current.deviceId}`}</Typography>
								<Table>
									<TableHead>
										<TableRow>
											<TableCell>Nombre</TableCell>
											<TableCell>Tipo</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{this.state.devices &&
											this.state.devices.map(d => (
												<TableRow>
													<TableCell>{d.name}</TableCell>
													<TableCell>{d.type}</TableCell>
												</TableRow>
											))}
									</TableBody>
								</Table>
							</React.Fragment>
						) : null}
					</DialogContent>
					<DialogActions>
						<Button onClick={this.onDialogClose}>Cerrar</Button>
					</DialogActions>
				</Dialog>
			</Paper>
		)
	}
}

const mapStateToProps = state => ({
	equipments: state.services.equipments
})

const mapDispatchToProps = dispatch => ({
	equipmentsFind: (query = {}) => dispatch(services.equipments.find(query)),
	store: data => dispatch(services.equipments.store(data))
})

export default withStyles(styles)(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(EquipmentsList)
)
