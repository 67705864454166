import locationHelperBuilder from 'redux-auth-wrapper/history4/locationHelper'
import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect'
import connectedAuthWrapper from 'redux-auth-wrapper/connectedAuthWrapper'

// import Authenticating from './components/Authenticating'

const locationHelper = locationHelperBuilder({})

const userIsAuthenticatedDefaults = {
	authenticatedSelector: state => state.auth.isSignedIn,
	authenticatingSelector: state => state.auth.isLoading,
	wrapperDisplayName: 'UserIsAuthenticated'
}

export const userIsAuthenticated = connectedAuthWrapper(
	userIsAuthenticatedDefaults
)

export const userIsAuthenticatedRedir = connectedRouterRedirect({
	...userIsAuthenticatedDefaults,
	// AuthenticatingComponent: Authenticating,
	redirectPath: '/login'
})

// export const userIsAdminRedir = connectedRouterRedirect({
//     redirectPath: '/',
//     allowRedirectBack: false,
//     authenticatedSelector: state => state.user.data !== null && state.user.data.isAdmin,
//     predicate: user => user.isAdmin,
//     wrapperDisplayName: 'UserIsAdmin'
// })

const userIsNotAuthenticatedDefaults = {
	// Want to redirect the user when they are done loading and authenticated
	authenticatedSelector: state => state.auth.isSignedIn === false,
	wrapperDisplayName: 'UserIsNotAuthenticated'
}

export const userIsNotAuthenticated = connectedAuthWrapper(
	userIsNotAuthenticatedDefaults
)

export const userIsNotAuthenticatedRedir = connectedRouterRedirect({
	...userIsNotAuthenticatedDefaults,
	redirectPath: (state, ownProps) => {
        if (locationHelper.getRedirectQueryParam(ownProps)) {
            return locationHelper.getRedirectQueryParam(ownProps)
        } else {
            return state.auth.user ? getUserHome(state.auth.user) : '/'
        }
	},
	allowRedirectBack: false
})

export const getUserHome = (user) => {
    if (user) {
        if (user.role === 'SUPERVISOR') return '/equipments'
    
        return '/monitoring'
    }

    return '/'
}