import React, { Component } from 'react';
import { hot } from 'react-hot-loader';
import { Route, Switch } from 'react-router-dom';
import { CssBaseline } from '@material-ui/core/';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { grey, yellow } from '@material-ui/core/colors';
import RootContainer from './containers/RootContainer';
import LoginContainer from './containers/LoginContainer';
import { userIsAuthenticatedRedir, userIsNotAuthenticatedRedir } from './Auth';

const Login = userIsNotAuthenticatedRedir(LoginContainer);
const Root = userIsAuthenticatedRedir(RootContainer);

const theme = createMuiTheme({
	typography: {
		useNextVariants: true
	},
	palette: {
		primary: {
			main: grey[900]
		},
		secondary: {
			main: yellow[600]
		},
		// Used by `getContrastText()` to maximize the contrast between the background and
		// the text.
		contrastThreshold: 3
		// Used to shift a color's luminance by approximately
		// two indexes within its tonal palette.
		// E.g., shift from Red 500 to Red 300 or Red 700.
		// tonalOffset: 0.2,
	},
	overrides: {
		MuiTooltip: {
			tooltip: {
				fontSize: 15,
			}
		}
	}
});

class App extends Component {
	render() {
		return (
			<div style={{ display: 'flex', minHeight: '100vh' }}>
				<CssBaseline />
				<MuiThemeProvider theme={theme}>
					<Switch>
						<Route exact path="/login" component={Login} />
						<Route path="/" component={Root} />
					</Switch>
				</MuiThemeProvider>
			</div>
		);
	}
}

export default hot(module)(App)
