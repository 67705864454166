import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Switch, Route } from 'react-router-dom'
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import HomeContainer from './HomeContainer'
import ClientsList from './Clients/ClientsList'
import EquipmentsList from './Equipments/EquipmentsList'
import EventsList from './Events/EventsList'
import ClientsCreate from './Clients/ClientsCreate'
import EquipmentsCreate from './Equipments/EquipmentsCreate'
import EquipmentsEdit from './Equipments/EquipmentsEdit'
import ClientsEdit from './Clients/ClientsEdit'
import UsersList from './Users/UsersList'
import UsersCreate from './Users/UsersCreate'
import UsersEdit from './Users/UsersEdit'
import MonitoringContainer from './Monitoring/MonitoringContainer'
import AuthorizedRoute from '../components/AuthorizedRoute'

const styles = theme => ({
	appBarSpacer: theme.mixins.toolbar,
	content: {
		flexGrow: 1,
		padding: theme.spacing.unit * 3,
		minHeight: '100vh',
		overflow: 'auto'
	}
})

class MainContainer extends Component {
	render() {
		const { classes } = this.props

		return (
			<main className={classes.content}>
				<div className={classes.appBarSpacer} />
				<Switch>
					{/* <Route exact path="/" component={HomeContainer} /> */}
					<AuthorizedRoute
						exact
						path="/clients"
						component={ClientsList}
						authorizedRoles={['admin']}
					/>
					<AuthorizedRoute
						authorizedRoles={['admin', 'supervisor']}
						exact
						path="/clients/create"
						component={ClientsCreate}
					/>
					<AuthorizedRoute
						authorizedRoles={['admin', 'supervisor']}
						exact
						path="/clients/:id/edit"
						component={ClientsEdit}
					/>
					<AuthorizedRoute
						authorizedRoles={['admin', 'supervisor']}
						exact
						path="/equipments"
						component={EquipmentsList}
					/>
					<AuthorizedRoute
						authorizedRoles={['admin', 'supervisor']}
						exact
						path="/equipments/create"
						component={EquipmentsCreate}
					/>
					<AuthorizedRoute
						authorizedRoles={['admin', 'supervisor']}
						exact
						path="/equipments/:id/edit"
						component={EquipmentsEdit}
					/>
					<AuthorizedRoute
						authorizedRoles={['admin', 'operator', 'supervisor']}
						exact
						path="/events"
						component={EventsList}
					/>
					<AuthorizedRoute
						authorizedRoles={['admin']}
						exact
						path="/users"
						component={UsersList}
					/>
					<AuthorizedRoute
						authorizedRoles={['admin']}
						exact
						path="/users/create"
						component={UsersCreate}
					/>
					<AuthorizedRoute
						authorizedRoles={['admin']}
						exact
						path="/users/:id/edit"
						component={UsersEdit}
					/>
					<AuthorizedRoute
						authorizedRoles={['admin', 'operator']}
						exact
						path="/monitoring"
						component={MonitoringContainer}
					/>
				</Switch>
			</main>
		)
	}
}

export default withStyles(styles)(MainContainer)
