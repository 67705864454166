import React, { PureComponent } from 'react'
import {
	Typography,
	Grid,
	Table,
	TableHead,
	TableRow,
	TableBody,
	TableCell,
	Chip
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import moment from 'moment'
import { green } from '@material-ui/core/colors'
import Segment from './Segment'

const styles = {
	table: {
		width: '100%',
		flex: 1,
		maxWidth: '100%'
	},
	chip: {
		marginLeft: 20,
		backgroundColor: green[600],
		color: 'white',
		fontWeight: 500
	}
}

class EventObservationsSection extends PureComponent {
    getObservationType = (type) => {
        if (type === 'INITCALL') {
            return 'Inicio de llamada'
        }

        if (type === 'ENDCALL') {
            return 'Finalización de evento'
        }

        return 'Observación'
    }
    
	render() {
		const { classes, event, observations } = this.props

		return (
			<Grid container spacing={16} style={{ marginTop: 8 }}>
				<Grid item lg={12}>
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<Typography variant="subtitle1">Anotaciones</Typography>
						{event.resolved ? (
							<Chip label="Evento finalizado" className={classes.chip} />
						) : null}
					</div>

					<Segment loading={observations.isLoading}>
						<Table className={classes.table}>
							<TableHead>
								<TableRow>
									<TableCell>Tipo</TableCell>
									<TableCell>Mensaje</TableCell>
									<TableCell>Usuario</TableCell>
									<TableCell>Fecha hora</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{observations.queryResult.data.map(row => (
									<TableRow key={row._id}>
										<TableCell component="th" scope="row">
											{this.getObservationType(row.type)}
										</TableCell>
										<TableCell>{row.message}</TableCell>
										<TableCell>{`${row.user.name} ${
											row.user.lastName
										}`}</TableCell>
										<TableCell>
											{moment(row.createdAt)
												.local()
												.format('YYYY-MM-DD HH:mm:ss')}
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</Segment>
				</Grid>
			</Grid>
		)
	}
}

export default withStyles(styles)(EventObservationsSection)
