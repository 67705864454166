import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Typography, Snackbar, Grid, Dialog, Slide } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import feathersClient, { services } from '../../redux/FeathersRedux'
import MySnackbarContent from '../../components/MySnackbar'
import MonitoringItem from './MonitoringItem'
import MonitoringClientDialog from './MonitoringClientDialog'
import Segment from '../../components/Segment'

const styles = theme => ({
	iconSmall: {
		fontSize: 18
	},
	fab: {
		margin: theme.spacing.unit,
		position: 'absolute',
		bottom: theme.spacing.unit * 2,
		right: theme.spacing.unit * 2
	}
})

const Transition = props => {
	return <Slide direction="up" {...props} />
}

// const ROWS_PER_PAGE = 10;

class EventsList extends Component {
	state = {
		client: {},
		currentEvent: {},
		dialogOpen: false,
		snackbar: {
			open: false,
			message: '',
			variant: 'success'
		},
		events: {
			data: [],
			isLoading: false,
		}
	}

	componentDidMount() {
		this.getData(0)

		this.createdEventListener = feathersClient
			.service('events')
			.on('created', this.getData)

		this.patchedEventListener = feathersClient
			.service('events')
			.on('patched', this.getData)
	}

	componentWillUnmount() {
		this.createdEventListener.off()
		this.patchedEventListener.off()
	}

	getData = async () => {
		await this.setState({
			events: {
				...this.state.events,
				isLoading: true
			}
		})

		try {
			const response = await feathersClient.service('events').find({
				query: {
					alarmStatus: 'INTRUSION',
					$limit: 50,
					$sort: {
						createdAt: -1
					},
					$populate: [
						{
							path: 'device'
						},
						{
							path: 'equipment',
							populate: {
								path: 'client'
							}
						},
						{
							path: 'user'
						}
					]
				}
			})

			this.setState({
				events: {
					data: response.data,
					isLoading: false
				}
			})

		} catch (error) {
			this.setState({
				isLoading: false
			})
		}

	}

	onDialogClose = () => {
		this.setState({
			dialogOpen: false,
			currentEvent: {}
		})
	}

	openDialogClient = event => {
		this.setState({ currentEvent: event, dialogOpen: true })
		this.props.findObservations({
			event: event._id
		})
	}

	assignAndShowEvent = async event => {
		if (!event.user) {
			const { user, patchEvent } = this.props

			try {
				await patchEvent(event._id, {
					user: user._id
				})

				this.setState({
					snackbar: {
						open: true,
						message: 'Evento asignado',
						variant: 'info'
					}
				})

				// this.getData(0)
			} catch (e) {
				console.error(e)
			}
		}

		this.openDialogClient(event)

		//levantar informacion
	}

	/**
	 * Enables Snackbar component which displays API response
	 */
	openSnackbar = (message, variant) => {
		this.setState({
			snackbar: {
				open: true,
				message,
				variant
			}
		})
	}

	/**
	 * Closes Snackbar component
	 */
	closeSnackbar = () => {
		this.setState({
			snackbar: {
				...this.state.snackbar,
				open: false
			}
		})
	}

	renderItem = event => {
		return (
			<MonitoringItem
				event={event}
				assignAndShowEvent={this.assignAndShowEvent}
				onInfoButtonClick={this.openDialogClient}
			/>
		)
	}

	renderDialogClientDetails = () => {
		const { currentEvent } = this.state

		return (
			<Dialog
				open={this.state.dialogOpen}
				onClose={this.onDialogClose}
				fullScreen
				TransitionComponent={Transition}
			>
				<MonitoringClientDialog
					event={currentEvent}
					onClose={this.onDialogClose}
					createObservation={this.props.createObservation}
					patchEvent={this.props.patchEvent}
					observations={this.props.observations}
					findObservations={this.props.findObservations}
				/>
			</Dialog>
		)
	}

	render() {
		return (
			<React.Fragment>
				{this.state.events.isLoading ? null : (
					<Grid container spacing={16} style={{ padding: '0 16px' }}>
						<Grid item md>
							<Typography style={{ color: 'black' }}>No. cliente</Typography>
						</Grid>
						<Grid item md>
							<Typography style={{ color: 'black' }}>Cliente</Typography>
						</Grid>
						<Grid item md>
							<Typography style={{ color: 'black' }}>Dispositivo</Typography>
						</Grid>
						<Grid item md>
							<Typography style={{ color: 'black' }}>
								Tipo de dispositivo
							</Typography>
						</Grid>
						<Grid item md>
							<Typography style={{ color: 'black' }}>Zona</Typography>
						</Grid>
						<Grid item md>
							<Typography style={{ color: 'black' }}>Estatus alarma</Typography>
						</Grid>
						<Grid item md>
							<Typography style={{ color: 'black' }}>Fecha</Typography>
						</Grid>
						<Grid item md>
							<Typography style={{ color: 'black' }}>Operador</Typography>
						</Grid>
						<Grid item md={1}>
							<Typography style={{ color: 'black' }}>Acciones</Typography>
						</Grid>
					</Grid>
				)}

				<Segment loading={this.state.events.isLoading}>
					{this.state.events.data &&
						this.state.events.data.map(event => {
							return this.renderItem(event)
						})}
				</Segment>

				<this.renderDialogClientDetails />

				<Snackbar
					anchorOrigin={{
						vertical: 'top',
						horizontal: 'right'
					}}
					open={this.state.snackbar.open}
					autoHideDuration={5000}
					onClose={this.closeSnackbar}
				>
					<MySnackbarContent
						message={this.state.snackbar.message}
						variant={this.state.snackbar.variant}
						onClose={this.closeSnackbar}
					/>
				</Snackbar>
			</React.Fragment>
		)
	}
}

const mapStateToProps = state => ({
	user: state.auth.user,
	observations: state.services.eventobservations
})

const mapDispatchToProps = dispatch => ({
	patchEvent: (id, params = {}) => dispatch(services.events.patch(id, params)),
	createObservation: (data = {}) =>
		dispatch(services.eventobservations.create(data)),
	findObservations: (query = {}) =>
		dispatch(
			services.eventobservations.find({
				query: {
					...query,
					$sort: {
						createdAt: -1
					},
					$populate: [
						{
							path: 'user',
							select: ['name', 'lastName']
						}
					]
				}
			})
		)
})

export default withStyles(styles)(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(EventsList)
)
