import React, { Component } from 'react'
import {
	Toolbar,
	Typography,
	IconButton,
	Popover,
	TextField,
	MenuItem,
	Button,
	Grid,
	InputAdornment,
	Chip
} from '@material-ui/core'
import {
	Clear,
	ArrowDropUp,
	ArrowDropDown,
	AddCircle as AddCircleIcon,
	Refresh as RefreshIcon,
	FilterList as FilterIcon,
	Info as InfoIcon
} from '@material-ui/icons'
import {
	DateRangePicker,
} from 'react-dates'
import { withStyles } from '@material-ui/core/styles'
import feathersClient from '../../redux/FeathersRedux'
import MuiDownshift from 'mui-downshift'
import moment from 'moment'
import 'moment/locale/es'

moment.locale('es')

const RESOLVED_STATUS = [
	{
		label: 'Resuelto',
		value: true,
	},
	{
		label: 'Sin resolver',
		value: 'false',
	}
]

const ALARM_STATUS = [
	{
		label: 'Armado en casa',
		value: 'ARMED_STAY'
	},
	{
		label: 'Armado y fuera',
		value: 'ARMED_AWAY'
	},
	{
		label: 'Desarmado',
		value: 'DISARMED'
	},
	{
		label: 'Intrusión',
		value: 'INTRUSION'
	}
]

const styles = theme => ({
	iconSmall: {
		fontSize: 20
	},
	actionButton: {
		padding: theme.spacing.unit / 2
	},
	chip: {
		marginRight: 8
	}
})

const INITIAL_FILTERS = {
	alarmStatus: {
		label: '',
		value: ''
	},
	client: {
		label: '',
		value: ''
	},
	resolved: {
		label: '',
		value: '',
	},
	createdAt: null
}

class EventsListToolbar extends Component {
	state = {
		filters: INITIAL_FILTERS,
		appliedFilters: {},
		clients: [],
		focusedInput: null,
		dates: {
			startDate: null,
			endDate: null
		}
	}

	updateFilters = (name, value, doFilter = false) => {
		this.setState(
			{
				filters: {
					...this.state.filters,
					[name]: {
						...value
					}
				}
			},
			() => {
				if (doFilter) {
					this.doFilter()
				}
			}
		)
	}

	clearFilters = () => {
		this.setState(
			{
				filters: INITIAL_FILTERS
			},
			this.doFilter
		)
	}

	getClients = async query => {
		try {
			const clients = await feathersClient.service('clients').find({
				query: {
					$or: [
						{ name: { $search: query } },
						{ lastName: { $search: query } },
						{ clientNumber: { $search: query } }
					]
				}
			})

			this.setState({
				clients: clients.data.map(c => {
					return {
						label: `${c.name} ${c.lastName}`,
						value: c._id
					}
				})
			})
		} catch (e) {}
	}

	onClientChange = client => {
		this.updateFilters('client', client)
	}

	doFilter = () => {
		this.setState({
			appliedFilters: {
				...this.state.filters,
				createdAt: {
					...this.state.dates
				}
			}
		}, () => {
			this.props.onFilter && this.props.onFilter(this.getQueryForFilters())
		})
	}

	getQueryForFilters = () => {
		let query = {}
		const { appliedFilters } = this.state

		Object.keys(appliedFilters).forEach(f => {
			if (
				f === 'createdAt' &&
				appliedFilters.createdAt.startDate &&
				appliedFilters.createdAt.endDate
			) {
				query['createdAt'] = {
					$gte: appliedFilters.createdAt.startDate.toDate(),
					$lte: appliedFilters.createdAt.endDate.toDate()
				}
			}

			if (f && appliedFilters[f].value) {
				if (appliedFilters[f].type && appliedFilters[f].type === 'search') {
					query[f] = {
						$search: appliedFilters[f].value
					}
				} else {
					query[f] = appliedFilters[f].value
				}
			}
		})

		return query;
	}

	refreshData = () => {
		this.props.refreshData && this.props.refreshData()
	}

	render() {
		const { classes } = this.props
		const { anchorEl } = this.state
		const open = Boolean(anchorEl)

		return (
			<React.Fragment>
				<Toolbar>
					<Typography variant="h6" style={{ flex: 1 }}>
						{'Lista de eventos'}
					</Typography>
					<IconButton
						color="primary"
						onClick={event => {
							this.setState({
								anchorEl: event.currentTarget
							})
						}}
					>
						<FilterIcon />
					</IconButton>
					<Popover
						id="simple-popper"
						PaperProps={{
							style: {
								overflow: 'visible'
							}
						}}
						open={open}
						anchorEl={anchorEl}
						onClose={() => {
							this.setState({
								anchorEl: null
							})
						}}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'center'
						}}
						transformOrigin={{
							vertical: 'top',
							horizontal: 'center'
						}}
					>
						<div style={{ padding: '0 16px 16px', minWidth: 500 }}>
							<div style={{ textAlign: 'center', margin: '16px 0' }}>
								<Button
									variant="text"
									style={{ marginRight: 5 }}
									onClick={this.doFilter}
								>
									Filtrar
								</Button>
								<Button
									variant="text"
									style={{ fontSize: 12 }}
									onClick={this.clearFilters}
								>
									Reiniciar
								</Button>
							</div>

							<Grid container spacing={16}>
								<Grid item lg={6}>
									<MuiDownshift
										items={this.state.clients}
										variant="outlined"
										getInputProps={downshiftProps => {
											return {
												// fullWidth: true,
												label: 'Cliente',
												endAdornment: (
													<InputAdornment position="end">
														{!!downshiftProps.selectedItem && (
															<IconButton
																className={classes.actionButton}
																onClick={downshiftProps.clearSelection}
																aria-label="Clear selection"
															>
																<Clear />
															</IconButton>
														)}
														<IconButton
															className={classes.actionButton}
															onClick={downshiftProps.handleToggleMenu}
															aria-label="Toggle menu open"
														>
															{downshiftProps.isOpen ? (
																<ArrowDropUp />
															) : (
																<ArrowDropDown />
															)}
														</IconButton>
													</InputAdornment>
												)
											}
										}}
										onInputValueChange={this.getClients}
										onChange={this.onClientChange}
										// inputValue={this.state.filters.client.label}
										selectedItem={
											this.state.filters.client.label
												? this.state.filters.client
												: null
										}
									/>
								</Grid>
								<Grid item lg={6}>
									<TextField
										id="outlined-select-currency"
										select
										label="Estatus alarma"
										className={classes.textField}
										fullWidth
										value={this.state.filters.alarmStatus.value}
										onChange={e => {
											const alarmStatus = ALARM_STATUS.find(
												f => f.value === e.target.value
											)

											this.updateFilters('alarmStatus', {
												...alarmStatus
											})
										}}
										SelectProps={{
											MenuProps: {
												className: classes.menu
											}
										}}
										margin="none"
										variant="outlined"
									>
										{ALARM_STATUS.map(option => (
											<MenuItem key={option.value} value={option.value}>
												{option.label}
											</MenuItem>
										))}
									</TextField>
								</Grid>

								<Grid item lg={6}>
									<TextField
										id="outlined-select-currency"
										select
										label="Estatus"
										className={classes.textField}
										fullWidth
										value={this.state.filters.resolved.value}
										onChange={e => {
											const resolved = RESOLVED_STATUS.find(
												f => f.value === e.target.value
											)

											this.updateFilters('resolved', {
												...resolved
											})
										}}
										SelectProps={{
											MenuProps: {
												className: classes.menu
											}
										}}
										margin="none"
										variant="outlined"
									>
										{RESOLVED_STATUS.map(option => (
											<MenuItem key={option.value} value={option.value}>
												{option.label}
											</MenuItem>
										))}
									</TextField>
								</Grid>

								<Grid item lg={6}>
									<DateRangePicker
										startDate={this.state.dates.startDate} // momentPropTypes.momentObj or null,
										startDateId="startDate" // PropTypes.string.isRequired,
										endDate={this.state.dates.endDate} // momentPropTypes.momentObj or null,
										endDateId="endDate" // PropTypes.string.isRequired,
										onDatesChange={({ startDate, endDate }) =>
											this.setState({
												dates: {
													startDate,
													endDate
												}
											})
										} // PropTypes.func.isRequired,
										focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
										onFocusChange={focusedInput =>
											this.setState({ focusedInput })
										} // PropTypes.func.isRequired,
										// enableOutsideDays={false}
										isOutsideRange={() => false}
										block
										startDatePlaceholderText="Fecha inicio"
										endDatePlaceholderText="Fecha de fin"
									/>
								</Grid>
							</Grid>
						</div>
					</Popover>
					<IconButton color="primary" onClick={this.refreshData}>
						<RefreshIcon />
					</IconButton>
				</Toolbar>
				<div style={{ padding: '0 16px' }}>
					{Object.keys(this.state.appliedFilters).map(f => {
						if (
							f === 'createdAt' &&
							this.state.appliedFilters.createdAt.startDate &&
							this.state.appliedFilters.createdAt.endDate
						) {
							return (
								<Chip
									label={`${this.state.appliedFilters.createdAt.startDate.format('DD/MM/YYYY')} -> ${this.state.appliedFilters.createdAt.endDate.format('DD/MM/YYYY')}`}
									className={classes.chip}
									onDelete={() => {
										this.setState({
											dates: {
												startDate: null,
												endDate: null
											},
											filters: {
												...this.state.filters,
												createdAt: null
											}
										}, this.doFilter)
									}}
								/>
							)
						}

						if (this.state.appliedFilters[f].value) {
							return (
								<Chip
									label={this.state.appliedFilters[f].label}
									className={classes.chip}
									onDelete={() => {
										this.updateFilters(f, { label: '', value: '' }, true)
									}}
								/>
							)
						}

						return null
					})}
				</div>
			</React.Fragment>
		)
	}
}

export default withStyles(styles)(EventsListToolbar)
