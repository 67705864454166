import React, { Component } from 'react';
import Layout from './LayoutContainer';
import MainContainer from './MainContainer';

class RootContainer extends Component {
	render() {
		return (
			<React.Fragment>
				{/* Layout contains AppBar and Drawer */}
				<Layout />
				{/* RootContainer renders views */}
				<MainContainer />
			</React.Fragment>
		);
	}
}

export default RootContainer;
