import React, { Component } from 'react'
import {
	ListItem,
	ListItemIcon,
	ListItemText,
	List,
	ListSubheader,
	Divider,
	Typography
} from '@material-ui/core'
import DashboardIcon from '@material-ui/icons/Dashboard'
import CategoryIcon from '@material-ui/icons/Category'
import ListIcon from '@material-ui/icons/FormatListBulleted'
import LogoutIcon from '@material-ui/icons/PowerSettingsNew'
import AccountIcon from '@material-ui/icons/AccountCircle'
import { Link } from 'react-router-dom'
import { Person, PersonPin, Tv } from '@material-ui/icons'
import AuthorizedComponent from '../components/AuthorizedComponent'
import { withStyles } from '@material-ui/core/styles'

const styles = {
	whiteFont: {
		color: 'rgba(255,255,255,0.85)'
	},
	whiteDivider: {
		backgroundColor: 'rgba(255,255,255,0.2)'
	}
}

class MenuListItems extends Component {
	render() {
		const { classes } = this.props

		return (
			<div>
				<List>
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							padding: '8px 16px 16px',
							color: 'rgba(255, 255, 255, 0.70)'
						}}
					>
						<div
							style={{ marginRight: 16, display: 'flex', alignItems: 'center' }}
						>
							<AccountIcon color="inherit" />
						</div>
						<div style={{ flex: 1, flexDirection: 'column' }}>
							<div>
								<Typography
									style={{ fontWeight: 500, color: 'inherit' }}
								>{`Hola, ${this.props.user.name}`}</Typography>
							</div>
							<Typography style={{ fontSize: 12, color: 'inherit' }}>
								{this.props.user.role}
							</Typography>
						</div>
					</div>

					{/* <ListItem button component={Link} to="/">
						<ListItemIcon>
							<DashboardIcon className={classes.whiteFont} />
						</ListItemIcon>
						<ListItemText
							primary="Inicio"
							primaryTypographyProps={{ className: classes.whiteFont }}
						/>
					</ListItem> */}
					<AuthorizedComponent
						authorizedRoles={['admin', 'supervisor']}
						role={this.props.user.role}
					>
						<ListItem button component={Link} to="/clients">
							<ListItemIcon>
								<PersonPin className={classes.whiteFont} />
							</ListItemIcon>
							<ListItemText
								primary="Clientes"
								primaryTypographyProps={{ className: classes.whiteFont }}
							/>
						</ListItem>
					</AuthorizedComponent>
					<AuthorizedComponent
						authorizedRoles={['admin', 'supervisor']}
						role={this.props.user.role}
					>
						<ListItem button component={Link} to="/equipments">
							<ListItemIcon>
								<CategoryIcon className={classes.whiteFont} />
							</ListItemIcon>
							<ListItemText
								primary="Equipos"
								primaryTypographyProps={{ className: classes.whiteFont }}
							/>
						</ListItem>
					</AuthorizedComponent>
					<AuthorizedComponent
						authorizedRoles={['admin', 'operator', 'supervisor']}
						role={this.props.user.role}
					>
						<ListItem button component={Link} to="/events">
							<ListItemIcon>
								<ListIcon className={classes.whiteFont} />
							</ListItemIcon>
							<ListItemText
								primary="Eventos"
								primaryTypographyProps={{ className: classes.whiteFont }}
							/>
						</ListItem>
					</AuthorizedComponent>
					<AuthorizedComponent
						authorizedRoles={['admin']}
						role={this.props.user.role}
					>
						<ListItem button component={Link} to="/users">
							<ListItemIcon>
								<Person className={classes.whiteFont} />
							</ListItemIcon>
							<ListItemText
								primary="Usuarios"
								primaryTypographyProps={{ className: classes.whiteFont }}
							/>
						</ListItem>
					</AuthorizedComponent>
					<Divider className={classes.whiteDivider} />
					<AuthorizedComponent
						authorizedRoles={['admin', 'operator']}
						role={this.props.user.role}
					>
						<ListItem button component={Link} to="/monitoring">
							<ListItemIcon>
								<Tv className={classes.whiteFont} />
							</ListItemIcon>
							<ListItemText
								primary="Monitoreo"
								primaryTypographyProps={{ className: classes.whiteFont }}
							/>
						</ListItem>
						<Divider className={classes.whiteDivider} />
					</AuthorizedComponent>

					<ListItem button onClick={this.props.logOut}>
						<ListItemIcon>
							<LogoutIcon className={classes.whiteFont} />
						</ListItemIcon>
						<ListItemText
							primary="Fin de sesión"
							primaryTypographyProps={{ className: classes.whiteFont }}
						/>
					</ListItem>
				</List>
			</div>
		)
	}
}

export default withStyles(styles)(MenuListItems)
