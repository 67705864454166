import { combineReducers } from 'redux';
import { servicesReducer, feathersAuthentication } from './FeathersRedux';

/* ------------- Assemble The Reducers ------------- */
export default combineReducers({
    app: () => { return {} },
    services: servicesReducer,
    auth: feathersAuthentication.reducer,
    // auth: feathersAuthentication.reducer,
    // app: require('./AppRedux').reducer,
})

