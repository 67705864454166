import React, { Component } from 'react'
import { connect } from 'react-redux'
// import { services } from '../redux/FeathersRedux';

class HomeContainer extends Component {

    render() {
        return (
            <div>
                Bienvenido al portal de Conexión Alarmas ST!
            </div>
        )
    }
}

const mapStateToProps = state => ({
    // users: state.services.users
})

const mapDispatchToProps = (dispatch) => ({
    // getUsers: () => dispatch(services.users.find())
})

export default connect(mapStateToProps, mapDispatchToProps)(HomeContainer)