import React, { Component, Fragment } from 'react'
import {
	AppBar,
	Toolbar,
	IconButton,
	Typography,
	Grid,
	Dialog,
	DialogContent,
	Divider,
	ExpansionPanel,
	ExpansionPanelSummary,
	ExpansionPanelDetails,
	Fab,
	DialogTitle,
	TextField,
	DialogActions,
	Button,
	FormControl,
	Radio,
	RadioGroup,
	FormControlLabel,
	FormLabel,
} from '@material-ui/core'
import {
	// Refresh as RefreshIcon,
	Add as AddIcon,
	Close as CloseIcon,
} from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'
import { green } from '@material-ui/core/colors'
import EventObservationsSection from '../../components/EventObservationsSection';

const styles = theme => ({
	iconSmall: {
		fontSize: 18
	},
	fab: {
		margin: theme.spacing.unit,
		position: 'absolute',
		bottom: theme.spacing.unit * 2,
		right: theme.spacing.unit * 2
	},
	table: {
		width: '100%',
		flex: 1,
		maxWidth: '100%'
	},
	chip: {
		marginLeft: 20,
		backgroundColor: green[600],
		color: 'white',
		fontWeight: 500
	}
})

class MonitoringClientDialog extends Component {
	state = {
		formModalOpened: false,
		observation: {
			message: '',
			type: ''
		}
	}

	onClose = () => {
		this.props.onClose && this.props.onClose()
	}

	onFormModalClose = () => {
		this.setState({ formModalOpened: false })
	}

	openFormModal = () => {
		this.setState({
			formModalOpened: true
		})
	}

	onFormChange = name => e => {
		this.setState({
			observation: {
				...this.state.observation,
				[name]: e.target.value
			}
		})
	}

	addObservation = () => {
		const { createObservation, event, patchEvent } = this.props

		createObservation({
			...this.state.observation,
			event: event._id
		})
			.then(() => {
				// Si se finalizo el evento entonces patchear el evento
				if (this.state.observation.type === 'ENDCALL') {
					return patchEvent(event._id, {
						resolved: true
					})
				}

				return Promise.resolve()
			})
			.then(() => {
				this.setState({
					observation: {
						message: '',
						type: ''
					}
				})

				this.onFormModalClose()
				this.props.findObservations({
                    event: event._id
                })
			})
			.catch(error => {
				alert('Se produjo un error!')
				console.log(error)
			})
    }
    
    getObservationType = (type) => {
        if (type === 'INITCALL') {
            return 'Llamada'
        }

        if (type === 'ENDCALL') {
            return 'Finalización de evento'
        }

        return 'Observación'
    }

	render() {
		const { classes, event } = this.props

		if (!event.equipment) return null

		return (
			<Fragment>
				<AppBar color="primary" style={{ position: 'relative' }}>
					<Toolbar>
						<IconButton
							color="inherit"
							aria-label="Close"
							onClick={this.onClose}
						>
							<CloseIcon />
						</IconButton>

						<Typography variant="h6" color="inherit">
							{`Detalle de evento ${event._id}`}
						</Typography>
					</Toolbar>
				</AppBar>
				<DialogContent style={{ paddingTop: 16 }}>
					<Grid container spacing={16} style={{ marginBottom: 8 }}>
						<Grid item xs={12} lg={6} md={6}>
							<Typography variant="subtitle1">Cliente</Typography>
							<Divider />

							<Grid container spacing={16} style={{ paddingTop: 10 }}>
								<Grid item xs={12} lg={4} md={4}>
									<Typography variant="subtitle2">
										{'Número de cliente: '}
									</Typography>
								</Grid>
								<Grid item xs={12} lg={8} md={8}>
									<Typography
										variant="body2"
										style={{ display: 'inline-block' }}
									>
										{event.equipment.client.clientNumber}
									</Typography>
								</Grid>

								<Grid item xs={12} lg={4} md={4}>
									<Typography variant="subtitle2">{'Nombre: '}</Typography>
								</Grid>
								<Grid item xs={12} lg={8} md={8}>
									<Typography
										variant="body2"
										style={{ display: 'inline-block' }}
									>{`${event.equipment.client.name} ${
										event.equipment.client.lastName
									}`}</Typography>
								</Grid>

								<Grid item xs={12} lg={4} md={4}>
									<Typography variant="subtitle2">{'Dirección: '}</Typography>
								</Grid>
								<Grid item xs={12} lg={8} md={8}>
									<Typography
										variant="body2"
										style={{ display: 'inline-block' }}
									>{`${event.equipment.client.address}, entre ${
										event.equipment.client.betweenStreets
									}, ${event.equipment.client.suburb}, ${
										event.equipment.client.city
									}, ${event.equipment.client.zipcode}.`}</Typography>
								</Grid>

								<Grid item xs={12} lg={4} md={4}>
									<Typography variant="subtitle2">
										{'Tipo de residencia: '}
									</Typography>
								</Grid>
								<Grid item xs={12} lg={8} md={8}>
									<Typography
										variant="body2"
										style={{ display: 'inline-block' }}
									>{`${event.equipment.client.residenceType}`}</Typography>
								</Grid>

								<Grid item xs={12} lg={4} md={4}>
									<Typography variant="subtitle2">
										{'Palabra secreta: '}
									</Typography>
								</Grid>
								<Grid item xs={12} lg={8} md={8}>
									<Typography
										variant="body2"
										style={{ display: 'inline-block' }}
									>{`${event.equipment.client.keyword}`}</Typography>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={12} lg={6} md={6}>
							<Typography variant="subtitle1">Contactos del cliente</Typography>
							<Divider />
							{event.equipment.client.contacts &&
								event.equipment.client.contacts.map((c, index) => (
									<div key={index}>
										<ExpansionPanel>
											<ExpansionPanelSummary>
												<Typography
													variant="body2"
													style={{ display: 'inline-block' }}
												>{`${c.callPriority} - ${c.name}`}</Typography>
											</ExpansionPanelSummary>
											<ExpansionPanelDetails>
												<Grid container spacing={8}>
													<Grid item xs={12} lg={4} md={4}>
														<Typography variant="subtitle2">
															{'Correo: '}
														</Typography>
													</Grid>
													<Grid item xs={12} lg={8} md={8}>
														<Typography
															variant="body2"
															style={{ display: 'inline-block' }}
														>
															{c.email}
														</Typography>
													</Grid>

													<Grid item xs={12} lg={4} md={4}>
														<Typography variant="subtitle2">
															{'Teléfono: '}
														</Typography>
													</Grid>
													<Grid item xs={12} lg={8} md={8}>
														<Typography
															variant="body2"
															style={{ display: 'inline-block' }}
														>
															{c.phone}
														</Typography>
													</Grid>

													<Grid item xs={12} lg={4} md={4}>
														<Typography variant="subtitle2">
															{'Prioridad: '}
														</Typography>
													</Grid>
													<Grid item xs={12} lg={8} md={8}>
														<Typography
															variant="body2"
															style={{ display: 'inline-block' }}
														>
															{c.callPriority}
														</Typography>
													</Grid>
												</Grid>
											</ExpansionPanelDetails>
										</ExpansionPanel>
										<Divider />
									</div>
								))}
						</Grid>
					</Grid>

					<Divider />

					<EventObservationsSection event={event} observations={this.props.observations} />

					<Fab
						color="primary"
						aria-label="Add"
						className={classes.fab}
						onClick={this.openFormModal}
					>
						<AddIcon />
					</Fab>
				</DialogContent>

				<Dialog
					open={this.state.formModalOpened}
					onClose={this.handleClose}
					aria-labelledby="form-dialog-title"
					fullWidth
				>
					<DialogTitle id="form-dialog-title">Nueva anotación</DialogTitle>
					<DialogContent>
						<FormControl component="fieldset" className={classes.formControl}>
							<FormLabel component="legend">Tipo</FormLabel>
							<RadioGroup
								aria-label="Gender"
								name="gender1"
								className={classes.group}
								value={this.state.observation.type}
								onChange={this.onFormChange('type')}
							>
								<FormControlLabel
									value="INITCALL"
									control={<Radio />}
									label="Llamada"
								/>
								<FormControlLabel
									value="OBSERVATION"
									control={<Radio />}
									label="Observación"
								/>
								<FormControlLabel
									value="ENDCALL"
									control={<Radio />}
									label="Finalizar evento"
								/>
							</RadioGroup>
						</FormControl>
						<TextField
							id="outlined-multiline-flexible"
							label="Mensaje"
							multiline
							rowsMax="5"
							value={this.state.observation.message}
							onChange={this.onFormChange('message')}
							margin="normal"
							variant="outlined"
							fullWidth
						/>
					</DialogContent>
					<DialogActions>
						<Button onClick={this.onFormModalClose} color="primary">
							Cancelar
						</Button>
						<Button onClick={this.addObservation} disabled={this.props.observations.isLoading} color="primary">
							Agregar
						</Button>
					</DialogActions>
				</Dialog>
			</Fragment>
		)
	}
}

export default withStyles(styles)(MonitoringClientDialog)
