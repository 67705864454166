import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
    Paper,
    Toolbar,
    Typography,
    Link,
    Button,
    Grid,
    TextField,
    Snackbar,
    MenuItem,
    Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
} from '@material-ui/core'
import Segment from '../../components/Segment'
import { Link as RouterLink } from 'react-router-dom'
import SaveIcon from '@material-ui/icons/Save'
import EditIcon from '@material-ui/icons/Edit'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import MySnackbarContent from '../../components/MySnackbar'
// import { AddBox } from '@material-ui/icons'
import { services } from '../../redux/FeathersRedux'

const styles = theme => ({
    button: {
        margin: theme.spacing.unit,
    },
    leftIcon: {
        marginRight: theme.spacing.unit,
    },
    rightIcon: {
        marginLeft: theme.spacing.unit,
    },
    iconSmall: {
        fontSize: 20,
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
})

const INITIAL_DATA = {
    name: '',
    lastName: '',
    role: '',
    email: '',
    password: '',
}

const ROLES_TYPES = [
	{
		label: 'Administrador',
		value: 'ADMIN'
	},
	// {
	// 	label: 'Técnico',
	// 	value: 'TECNICAL'
	// },
	{
		label: 'Supervisor',
		value: 'SUPERVISOR'
	},
	{
		label: 'Operador',
		value: 'OPERATOR'
	},
]

class UsersEdit extends Component {
    state = {
        loading: true,
        openDialog: false,
        data: INITIAL_DATA,
        snackbar: {
            open: false,
            message: '',
            variant: 'success',
        },
        password: '',
        confirmPassword: '',
    }

    componentDidMount() {
        this.getUserData()
    }

    getUserData = () => {
        this.props.userGet(this.props.match.params.id)
        .then(res => {
            console.log(res);
            this.setState({ data: res.value, loading: false })
        })
        .catch(err => {
            console.log(err);
        })
    }

    handleClose = () => {
		this.setState({
			openDialog: false
		})
    }

    openDialog = () => {
		this.setState({
			openDialog: true
		})
	}

    /**
     * Set state for the current data
     */
    handleDataChange = name => (event) => {
        const { value } = event.target

        let newValue = value

        if (name === 'area_radio' && value) {
            newValue = +value
        }

        this.setState({
            data: {
                ...this.state.data,
                [name]: newValue,
            },
        })
    }

    handleDataPasswordChange = name => (event) => {
        const { value } = event.target

        this.setState({ [name]: value })
    }

    checkDisabled = () => {
        const { name, lastName, role, email, password } = this.state.data

        return name === '' || lastName === '' || role === '' || email === '' || password === ''
    }

    checkDisabledPassword = () => {
        const { password, confirmPassword } = this.state

        return password === '' || confirmPassword === '' || password !== confirmPassword
    }

    /**
     * Enables Snackbar component which displays API response
     */
    openSnackbar = (message, variant) => {
        this.setState({
            snackbar: {
                open: true,
                message,
                variant,
            },
        })
    }

    /**
     * Closes Snackbar component
     */
    closeSnackbar = () => {
        this.setState({
            snackbar: {
                ...this.state.snackbar,
                open: false,
            },
        })
    }

    changePassword = () => {
        const obj = {
            password: this.state.password
        }
        this.props.usersPatch(this.props.match.params.id, obj)
        .then(res => {
            console.log(res);
            this.openSnackbar('Contraseña actualizada correctamente', 'success')
        })
        .catch(err => {
            console.log(err);
            this.openSnackbar('Hubo un error al guardar', 'error')
        })
    }

    save = async () => {
        this.setState({ loading: true })
        this.props.usersPatch(this.props.match.params.id, this.state.data)
        .then(res => {
            console.log(res);
            this.openSnackbar('Guardo correctamente', 'success')
            this.setState({ loading: false })
        })
        .catch(err => {
            console.log(err);
            this.openSnackbar('Hubo un error al guardar', 'error')
            this.setState({ loading: false })
        })
    }

    renderToolbar = () => {
        const { classes } = this.props

        return (
            <Toolbar>
                <Typography variant="h6" style={{ flex: 1 }}>
                    <Link component={RouterLink} to="/users">
                        Usuarios
                    </Link>
                        {' > Editar'}
                </Typography>

                <Button
                    onClick={this.openDialog}
                    size="small"
                    color="secondary"
                    variant="contained"
                    disabled={this.checkDisabled()}
                    style={{ marginRight: 10 }}
                >
                    <EditIcon className={classNames(classes.leftIcon, classes.iconSmall)} />
                    Cambiar Contraseña
                </Button>

                <Button
                    onClick={this.save}
                    size="small"
                    color="primary"
                    variant="contained"
                    disabled={this.checkDisabled()}
                >
                    <SaveIcon className={classNames(classes.leftIcon, classes.iconSmall)} />
                    Guardar
                </Button>
            </Toolbar>
        )
    }

    renderForm = () => {
        // const { classes } = this.props
        return (
            <form>
                <Grid container spacing={16} style={{ paddingLeft: 16, paddingRight: 16, paddingBottom: 16 }}>

                    <Grid item xs={12} lg={12} md={12}>
                        <Typography variant="subtitle1" style={{ flex: 1 }}>
                            Datos personales
                        </Typography>
                    </Grid>
                    <Grid item xs={12} lg={3} md={3}>
                        <TextField
                            id="name"
                            label="Nombre"
                            value={this.state.data.name}
                            onChange={this.handleDataChange('name')}
                            margin="none"
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} lg={3} md={3}>
                        <TextField
                            id="lastName"
                            label="Apellido"
                            value={this.state.data.lastName}
                            onChange={this.handleDataChange('lastName')}
                            margin="none"
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} lg={3} md={3}>
                        <TextField
                            id="email"
                            label="Correo Electrónico"
                            value={this.state.data.email}
                            onChange={this.handleDataChange('email')}
                            margin="none"
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} lg={3} md={3}>
                        <TextField
                            select
                            label="Rol"
                            variant="outlined"
                            fullWidth
                            value={this.state.data.role}
                            onChange={this.handleDataChange('role')}
                        >
                            {ROLES_TYPES.map(option => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>

                </Grid>

            </form>
        )
    }

    renderDialogPassword = () => (
        <Dialog
            open={this.state.openDialog}
            onClose={this.handleClose}
            maxWidth="md"
        >
            <DialogTitle>Cambiar Contraseña</DialogTitle>
            <DialogContent>
                <Grid container spacing={16}>
                    <Grid item sm={12} md={12} lg={12}>
                        <TextField
                            type="password"
                            id="name"
                            label="Nueva contraseña"
                            value={this.state.password}
                            onChange={this.handleDataPasswordChange('password')}
                            margin="none"
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid item sm={12} md={12} lg={12}>
                        <TextField
                            type="password"
                            id="name"
                            label="Confirmar contraseña"
                            value={this.state.confirmPassword}
                            onChange={this.handleDataPasswordChange('confirmPassword')}
                            margin="none"
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>

                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={this.handleClose} color="primary">
                    Cancelar
                </Button>
                <Button onClick={this.changePassword} color="primary" disabled={this.checkDisabledPassword()}>
                    Agregar
                </Button>
            </DialogActions>
        </Dialog>
    )

    render() {
        return (
            <Paper>
                <this.renderToolbar />

                <Segment loading={this.state.loading}>
                    <this.renderForm />
                </Segment>

                <this.renderDialogPassword />

                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    open={this.state.snackbar.open}
                    autoHideDuration={10000}
                    onClose={this.closeSnackbar}
                >
                    <MySnackbarContent
                        message={this.state.snackbar.message}
                        variant={this.state.snackbar.variant}
                        onClose={this.closeSnackbar}
                    />
                </Snackbar>
            </Paper>
        )
    }
}

const mapStateToProps = state => ({
})

const mapDispatchToProps = (dispatch) => ({
    userGet: (id) => dispatch(services.users.get(id)),
    usersPatch: (id, user) => dispatch(services.users.patch(id, user))
})

export default withStyles(styles, {withTheme: true})(connect(
    mapStateToProps,
    mapDispatchToProps,
    )(UsersEdit))