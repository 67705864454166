import React, { Component, Fragment } from 'react'
import {
	AppBar,
	Toolbar,
	IconButton,
	Typography,
	Grid,
	DialogContent,
	Divider,
	ExpansionPanel,
	ExpansionPanelSummary,
	ExpansionPanelDetails,
} from '@material-ui/core'
import {
	// Refresh as RefreshIcon,
	Close as CloseIcon,
} from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'
import EventObservationsSection from '../../components/EventObservationsSection';

const styles = theme => ({
	iconSmall: {
		fontSize: 18
	},
	fab: {
		margin: theme.spacing.unit,
		position: 'absolute',
		bottom: theme.spacing.unit * 2,
		right: theme.spacing.unit * 2
	},
	table: {
		width: '100%',
		flex: 1,
		maxWidth: '100%'
	}
})

class EventsDetailDialog extends Component {
	onClose = () => {
		this.props.onClose && this.props.onClose()
	}

	render() {
		const { classes, event } = this.props

		if (!event.equipment) return null

		return (
			<Fragment>
				<AppBar style={{ position: 'relative' }}>
					<Toolbar>
						<IconButton
							color="inherit"
							aria-label="Close"
							onClick={this.onClose}
						>
							<CloseIcon />
						</IconButton>

						<Typography variant="h6" color="inherit">
							{`Detalle de evento ${event._id}`}
						</Typography>
					</Toolbar>
				</AppBar>
				<DialogContent style={{ paddingTop: 16 }}>
					<Grid container spacing={16} style={{ marginBottom: 8 }}>
						<Grid item xs={12} lg={6} md={6}>
							<Typography variant="subtitle1">Cliente</Typography>
							<Divider />

							<Grid container spacing={16} style={{ paddingTop: 10 }}>
								<Grid item xs={12} lg={4} md={4}>
									<Typography variant="subtitle2">
										{'Número de cliente: '}
									</Typography>
								</Grid>
								<Grid item xs={12} lg={8} md={8}>
									<Typography
										variant="body2"
										style={{ display: 'inline-block' }}
									>
										{event.equipment.client.clientNumber}
									</Typography>
								</Grid>

								<Grid item xs={12} lg={4} md={4}>
									<Typography variant="subtitle2">{'Nombre: '}</Typography>
								</Grid>
								<Grid item xs={12} lg={8} md={8}>
									<Typography
										variant="body2"
										style={{ display: 'inline-block' }}
									>{`${event.equipment.client.name} ${
										event.equipment.client.lastName
									}`}</Typography>
								</Grid>

								<Grid item xs={12} lg={4} md={4}>
									<Typography variant="subtitle2">{'Dirección: '}</Typography>
								</Grid>
								<Grid item xs={12} lg={8} md={8}>
									<Typography
										variant="body2"
										style={{ display: 'inline-block' }}
									>{`${event.equipment.client.address}, entre ${
										event.equipment.client.betweenStreets
									}, ${event.equipment.client.suburb}, ${
										event.equipment.client.city
									}, ${event.equipment.client.zipcode}.`}</Typography>
								</Grid>

								<Grid item xs={12} lg={4} md={4}>
									<Typography variant="subtitle2">
										{'Tipo de residencia: '}
									</Typography>
								</Grid>
								<Grid item xs={12} lg={8} md={8}>
									<Typography
										variant="body2"
										style={{ display: 'inline-block' }}
									>{`${event.equipment.client.residenceType}`}</Typography>
								</Grid>

								<Grid item xs={12} lg={4} md={4}>
									<Typography variant="subtitle2">
										{'Palabra secreta: '}
									</Typography>
								</Grid>
								<Grid item xs={12} lg={8} md={8}>
									<Typography
										variant="body2"
										style={{ display: 'inline-block' }}
									>{`${event.equipment.client.keyword}`}</Typography>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={12} lg={6} md={6}>
							<Typography variant="subtitle1">Contactos del cliente</Typography>
							<Divider />
							{event.equipment.client.contacts &&
								event.equipment.client.contacts.map(c => (
									<div>
										<ExpansionPanel>
											<ExpansionPanelSummary>
												<Typography
													variant="body2"
													style={{ display: 'inline-block' }}
												>{`${c.callPriority} - ${c.name}`}</Typography>
											</ExpansionPanelSummary>
											<ExpansionPanelDetails>
												<Grid container spacing={8}>
													<Grid item xs={12} lg={4} md={4}>
														<Typography variant="subtitle2">
															{'Correo: '}
														</Typography>
													</Grid>
													<Grid item xs={12} lg={8} md={8}>
														<Typography
															variant="body2"
															style={{ display: 'inline-block' }}
														>
															{c.email}
														</Typography>
													</Grid>

													<Grid item xs={12} lg={4} md={4}>
														<Typography variant="subtitle2">
															{'Teléfono: '}
														</Typography>
													</Grid>
													<Grid item xs={12} lg={8} md={8}>
														<Typography
															variant="body2"
															style={{ display: 'inline-block' }}
														>
															{c.phone}
														</Typography>
													</Grid>

													<Grid item xs={12} lg={4} md={4}>
														<Typography variant="subtitle2">
															{'Prioridad: '}
														</Typography>
													</Grid>
													<Grid item xs={12} lg={8} md={8}>
														<Typography
															variant="body2"
															style={{ display: 'inline-block' }}
														>
															{c.callPriority}
														</Typography>
													</Grid>
												</Grid>
											</ExpansionPanelDetails>
										</ExpansionPanel>
										<Divider />
									</div>
								))}
						</Grid>
					</Grid>

					<Divider />

					<EventObservationsSection event={event} observations={this.props.observations} />					

				</DialogContent>
			</Fragment>
		)
	}
}

export default withStyles(styles)(EventsDetailDialog)
