import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
	Paper,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	IconButton,
	TablePagination,
	Chip,
	Dialog,
	Slide,
	Tooltip
} from '@material-ui/core'
import { green } from '@material-ui/core/colors'
import { Info as InfoIcon, CheckCircle as CheckIcon } from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'
import Segment from '../../components/Segment'
import { services } from '../../redux/FeathersRedux'
import moment from 'moment'
import EventsListToolbar from './EventsListToolbar'
import EventsDetailDialog from './EventsDetailDialog'

const styles = theme => ({
	iconSmall: {
		fontSize: 20
	},
	actionButton: {
		padding: theme.spacing.unit / 2
	},
	chip: {
		marginRight: 8
	},
	alarmChipIntrusion: {
		backgroundColor: '#e53935',
		height: 28,
		fontSize: 12,
		color: 'white'
	},
	alarmChip: {
		backgroundColor: '#1565c0',
		height: 28,
		fontSize: 12,
		color: 'white'
	},
	checkCircle: {
		color: green[600]
	}
})

const Transition = props => {
	return <Slide direction="up" {...props} />
}

class EventsList extends Component {
	state = {
		rowsPerPage: 10,
		currentEvent: {},
		query: {}
	}

	componentDidMount() {
		if (!this.props.events.store) {
			this.getData(0)
		}
	}

	getData = (page = 0) => {
		// this.props.store({ page })

		const query = this.state.query || {}

		this.props.findEvents({
			query: {
				$skip: this.state.rowsPerPage * page,
				$limit: this.state.rowsPerPage,
				$sort: {
					createdAt: -1
				},
				$populate: [
					{
						path: 'device'
					},
					{
						path: 'equipment',
						populate: ['client']
					},
					{
						path: 'user'
					}
				],
				...query
			}
		})
	}

	onChangePage = (e, page) => {
		this.getData(page)
	}

	handleChangeRowsPerPage = (e, rows) => {
		this.setState({ rowsPerPage: rows.key })
		this.getData(0)
	}

	refreshData = () => {
		this.getData(
			this.props.events.queryResult.skip / this.state.rowsPerPage,
		)
	}

	openDialog = event => {
		this.setState({ currentEvent: event, dialogOpen: true })
		this.props.findObservations({
			event: event._id
		})
	}

	onDialogClose = () => {
		this.setState({
			dialogOpen: false,
			currentEvent: {}
		})
	}

	render() {
		const { classes } = this.props

		return (
			<Paper>
				<EventsListToolbar
					onFilter={query => {
						this.setState({ query }, this.getData)
					}}
					refreshData={this.refreshData}
				/>

				<Segment loading={this.props.events.isLoading}>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>Estatus</TableCell>
								<TableCell>No. cliente</TableCell>
								<TableCell>Cliente</TableCell>
								<TableCell>Equipo</TableCell>
								<TableCell>Dispositivo</TableCell>
								<TableCell>Tipo de dispositivo</TableCell>
								<TableCell>Estatus alarma</TableCell>
								<TableCell>Fecha</TableCell>
								<TableCell>Operador</TableCell>
								<TableCell>Acciones</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{this.props.events.queryResult.data.map(row => (
								<TableRow key={row._id}>
									<TableCell>
										{row.resolved ? (
											<Tooltip title="Resuelto">
												<CheckIcon
													className={classes.checkCircle}
													fontSize="small"
												/>
											</Tooltip>
										) : (
											'---'
										)}
									</TableCell>
									<TableCell component="th" scope="row">
										{row.equipment.client.clientNumber}
									</TableCell>
									<TableCell>
										{row.equipment.client.name} {row.equipment.client.lastName}
									</TableCell>
									<TableCell>{row.equipment.serialNumber}</TableCell>
									<TableCell>{row.device ? row.device.name : '---'}</TableCell>
									<TableCell>{row.device ? row.device.type : '---'}</TableCell>
									<TableCell>
										<Chip
											label={row.alarmStatus}
											className={
												row.alarmStatus === 'INTRUSION'
													? classes.alarmChipIntrusion
													: classes.alarmChip
											}
										/>
									</TableCell>
									<TableCell>
										{moment(row.createdAt).format('DD/MM/YYYY HH:mm:ss')}
									</TableCell>
									<TableCell>
										{row.user ? `${row.user.name} ${row.user.lastName}` : ''}
									</TableCell>
									<TableCell>
										<IconButton
											color="primary"
											className={classes.actionButton}
											onClick={() => this.openDialog(row)}
										>
											<InfoIcon className={classes.iconSmall} />
										</IconButton>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>

					<TablePagination
						rowsPerPageOptions={[5, 10, 25]}
						component="div"
						count={this.props.events.queryResult.total}
						rowsPerPage={this.state.rowsPerPage}
						page={this.props.events.queryResult.skip / this.state.rowsPerPage}
						backIconButtonProps={{
							'aria-label': 'Previous Page'
						}}
						nextIconButtonProps={{
							'aria-label': 'Next Page'
						}}
						onChangePage={this.onChangePage}
						onChangeRowsPerPage={this.handleChangeRowsPerPage}
						labelRowsPerPage="Registros por página:"
					/>
				</Segment>

				<Dialog
					open={this.state.dialogOpen}
					onClose={this.onDialogClose}
					fullScreen
					TransitionComponent={Transition}
				>
					<EventsDetailDialog
						event={this.state.currentEvent}
						onClose={this.onDialogClose}
						createObservation={this.props.createObservation}
						observations={this.props.observations}
						findObservations={this.props.findObservations}
					/>
				</Dialog>
			</Paper>
		)
	}
}

const mapStateToProps = state => ({
	events: state.services.events,
	observations: state.services.eventobservations
})

const mapDispatchToProps = dispatch => ({
	findEvents: (query = {}) => dispatch(services.events.find(query)),
	findObservations: (query = {}) =>
		dispatch(
			services.eventobservations.find({
				query: {
					...query,
					$sort: {
						createdAt: -1
					},
					$populate: [
						{
							path: 'user',
							select: ['name', 'lastName']
						}
					]
				}
			})
		)
})

export default withStyles(styles)(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(EventsList)
)
