import Config from '../config'

export const generateSTCode = (equipmentId) => {

    let code = `
/**
 *
 *
 */
import groovy.time.TimeCategory
import groovy.json.JsonOutput

public static String version() { return "${Config.stAppVersion}" }

public static String NAServerAPIUrl() { return "${Config.apiUrl}" }
public static String NAEquipment() { return "${equipmentId}" }
public static String NAAPIKey() { return "84496239-e3e8-482f-b507-0eb3ba1432a0" }

definition(
    name: "Conexion Alarmas",
    namespace: "conexion_alarmas",
    author: "acsyt",
    description: "App de SmartThings con Conexión Alarmas",
    category: "Safety & Security",
    iconUrl: "${Config.apiUrl}/images/200-01.png",
    iconX2Url: "${Config.apiUrl}/images/500-01.png",
    iconX3Url: "${Config.apiUrl}/images/1000-01.png")

preferences {
  page(name: "pageConfiguration", install: true, uninstall: true, content: "pageConfiguration")
}

def pageConfiguration() {
    dynamicPage(name: "pageConfiguration") {
      section {
        paragraph("Brindamos la seguridad que su hogar y negocio necesitan.",
          image:       "${Config.apiUrl}/images/200-01.png")
      }

      section("Sensores") {
        paragraph "Conexión alarmas recibirá información de los siguientes dispositivos cuando alguna alarma se active."
        input "contactSensors", "capability.contactSensor", title: "Puertas y ventanas", multiple: true, required: false
        input "motionSensors", "capability.motionSensor", title: "Detectores de movimiento", multiple: true, required: false
        input "smokeDetectors", "capability.smokeDetector", title: "Detectores de humo", multiple: true, required: false
        input "coDetectors", "capability.carbonMonoxideDetector", title: "Detectores de monóxido de carbono", multiple: true, required: false
        input "tempSensors", "capability.temperatureMeasurement", title: "Sensores de temperatura", multiple: true, required: false
      }
    }
}

def installed() {
  ` + 'log.debug "Installed with settings: ${settings}"' + `

  initialize()
}

def updated() {
  ` + 'log.debug "Updated with settings: ${settings}" ' + `
  sendDevicesToNAServer()

  initialize()
}

def getDevices() { 
  def devices = []
  settings.each { k, v ->
      settings.get(k).each {
          def id = it.device.id
          def name = it.name
          def displayName = it.device.displayName
          def group = ` + '"${it.device.group}"' + `
          devices << JsonOutput.toJson([ _id: id, name: displayName, type: name, equipmentId: NAEquipment(), group: group])
      }
    }

  return devices
}

def sendDevicesToNAServer() { 
    def defaultParams = [
    	uri: NAServerAPIUrl() + 'devices',
	    body: [
        	devices: getDevices()
        ],
        headers: ['x-api-key': NAAPIKey()]
    ]
    
    try {
        httpPostJson(defaultParams) { resp ->
            //log.debug "response data: ` + '${resp.data}' + `"
        }
    } catch (e) {
    	//log.debug "something went wrong while sending request to NA Server: $e"
    }
}

def initialize() {
	unsubscribe()
	subscribe(location, "alarmSystemStatus", alarmStatusHandler)
    subscribe(contactSensors, "contact", eventHandler)
    subscribe(motionSensors, "motion", eventHandler)
}

def alarmStatusHandler(evt) {
    
    def alarmStatus = 'DISARMED'
    
    if (evt.value == 'stay') {
    	alarmStatus = 'ARMED_STAY'
    } else if (evt.value == 'away') {
    	alarmStatus = 'ARMED_AWAY'
    }
    
    def params = [
    	alarmStatus: alarmStatus
    ]
    
    sendEventToNAServer(params)
}

def eventHandler(evt) {
	def currentAlarmState = location.currentState("alarmSystemStatus")?.value
    def deviceValue = evt.value
    
	if (currentAlarmState != "off" && (deviceValue == "open" || deviceValue == 'active')) {
    	def deviceId = evt.deviceId
        
        def params = [
        	device: deviceId,
            alarmStatus: 'INTRUSION'
        ]
        
        sendEventToNAServer(params)
    }
}

def sendEventToNAServer(Map body) {
	
    def defaultParams = [
    	uri: NAServerAPIUrl() + 'events',
	    body: [
        	equipment: NAEquipment()
        ],
        headers: ['x-api-key': NAAPIKey()]
    ]
    
    defaultParams.body << body
    
    try {
        httpPostJson(defaultParams) { resp ->
           // log.debug "response data: ` + '${resp.data}' + `"
        }
    } catch (e) {
    	//log.debug "something went wrong while sending request to NA Server: $e"
    }

}

def parseDate(dateStr) {
  try {
	Date.parse("yyyy-MM-dd'T'HH:mm:ss.SSSX", dateStr)
  } catch(ParseException) {
  	Date.parse("yyyy-MM-dd'T'HH:mm:ssX", dateStr)
  }
}
    `

    return code
}

const Utils = {
    generateSTCode
}

export default Utils